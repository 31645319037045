import React from 'react';
import { motion } from 'framer-motion';

export default function PageNotFound() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="750"
      height="500"
      fill="none"
      viewBox="0 0 750 500"
      className="w-7/12 sm:w-6/12 md:w-5/12 xl:w-4/12 md:mr-8"
    >
      <g id="404-error-page">
        <g id="freepik Stains inject 39">
          <path
            id="elpyt2xxny6gm"
            fill="#13CB6E"
            d="M48.54 242.16c-4.21-24.3 4.24-51.14 23.63-66.36 19.39-15.22 49.53-16.35 68.32-.39 13.21 11.23 19.39 28.37 27.26 43.82 7.87 15.45 19.79 31.09 36.92 33.76 14.78 2.3 29.64-5.78 44.47-3.84 14.48 1.89 26.6 13.73 30.77 27.72 4.17 13.99 1 29.54-6.42 42.08s-19 22.31-31.55 29.71c-40.49 23.81-94.22 23-134-1.93s-63.84-73-60-119.83"
          ></path>
          <g id="el7tct605miss">
            <g id="Group" opacity="0.7">
              <path
                id="el4ppoouy0vrm"
                fill="#fff"
                d="M48.54 242.16c-4.21-24.3 4.24-51.14 23.63-66.36 19.39-15.22 49.53-16.35 68.32-.39 13.21 11.23 19.39 28.37 27.26 43.82 7.87 15.45 19.79 31.09 36.92 33.76 14.78 2.3 29.64-5.78 44.47-3.84 14.48 1.89 26.6 13.73 30.77 27.72 4.17 13.99 1 29.54-6.42 42.08s-19 22.31-31.55 29.71c-40.49 23.81-94.22 23-134-1.93s-63.84-73-60-119.83"
                opacity="0.7"
              ></path>
            </g>
          </g>
          <path
            id="ela2pamx0i999"
            fill="#13CB6E"
            d="M283.11 459.659c13.15-9 19.33-28.76 11.11-42.39-8.32-12.4-21.24-22.29-36-24.38-14.76-2.09-31.15 4.9-37.73 18.31-4.15 8.44-4.16 18.64-.72 27.39a43.529 43.529 0 0018.19 20.91c13.59 8.22 32.04 9.14 45.15.16z"
          ></path>
          <g id="eluakiut3vevq">
            <g id="Group_2" opacity="0.7">
              <path
                id="elg79tkbmv7pd"
                fill="#fff"
                d="M283.11 459.659c13.15-9 19.33-28.76 11.11-42.39-8.32-12.4-21.24-22.29-36-24.38-14.76-2.09-31.15 4.9-37.73 18.31-4.15 8.44-4.16 18.64-.72 27.39a43.529 43.529 0 0018.19 20.91c13.59 8.22 32.04 9.14 45.15.16z"
                opacity="0.7"
              ></path>
            </g>
          </g>
          <path
            id="elaabv3ud5zrt"
            fill="#13CB6E"
            d="M710.11 376.83c-.81-21.05-11.24-41.92-28.7-53.7-17.46-11.78-41.77-13.39-59.76-2.44-11.21 6.82-19.34 17.68-26.11 28.92-3.71 6.16-7.58 12.95-14.25 15.64-8.67 3.51-18.2-1.27-27.37-3.1a43.057 43.057 0 00-50 30.94c-8 33.19 15.27 56.88 40.15 71.26 27.81 16.08 63 16.06 93.24 5.34 19.85-7 38.35-18.6 51.87-34.75 13.52-16.15 21.75-37.06 20.93-58.11z"
          ></path>
          <g id="el9b9j5r9rdft">
            <g id="Group_3" opacity="0.7">
              <path
                id="elk97v8nu7by"
                fill="#fff"
                d="M710.11 376.83c-.81-21.05-11.24-41.92-28.7-53.7-17.46-11.78-41.77-13.39-59.76-2.44-11.21 6.82-19.34 17.68-26.11 28.92-3.71 6.16-7.58 12.95-14.25 15.64-8.67 3.51-18.2-1.27-27.37-3.1a43.057 43.057 0 00-50 30.94c-8 33.19 15.27 56.88 40.15 71.26 27.81 16.08 63 16.06 93.24 5.34 19.85-7 38.35-18.6 51.87-34.75 13.52-16.15 21.75-37.06 20.93-58.11z"
                opacity="0.7"
              ></path>
            </g>
          </g>
          <path
            id="el5bedyum4cgl"
            fill="#13CB6E"
            d="M662.31 197c-2.57-16.8-18.77-31.9-35.68-30.28-15.79 2.29-31.28 10.16-40.12 23.44-8.84 13.28-9.67 32.25.2 44.78 6.21 7.89 16 12.62 26 13.35a46.485 46.485 0 0028.53-7.86c14.25-9.3 23.64-26.61 21.07-43.43z"
          ></path>
          <g id="elg38m8vocg2l">
            <g id="Group_4" opacity="0.7">
              <path
                id="elogp0byaiw7l"
                fill="#fff"
                d="M662.31 197c-2.57-16.8-18.77-31.9-35.68-30.28-15.79 2.29-31.28 10.16-40.12 23.44-8.84 13.28-9.67 32.25.2 44.78 6.21 7.89 16 12.62 26 13.35a46.485 46.485 0 0028.53-7.86c14.25-9.3 23.64-26.61 21.07-43.43z"
                opacity="0.7"
              ></path>
            </g>
          </g>
        </g>
        <motion.g
          id="boy"
          animate={{ y: [4, 0, 4] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <path
            id="el60s0gn9qtzl"
            fill="#263238"
            d="M466.52 201.23c2.18-.27 3.91-1.87 5.89-2.8a10.216 10.216 0 019.42.47c2.81 1.68 4.81 3.7 5 7l-3.67 4"
          ></path>
          <path
            id="elplqys9ngfwc"
            fill="#F4D3BD"
            d="M489.76 221.67l-5.9 35.41c-1 6-3.7 14.67-13.15 12.65-8.07-2.37-8.86-10.55-7.92-16.17.45-2.67.82-4.89.84-5.17 0 0-8.87-2.35-8-11.38.41-4.36 2-13.29 3.48-21.34A16.9 16.9 0 01477.44 202l.87.09c9.33 1.52 13.24 10.29 11.45 19.58z"
          ></path>
          <path
            id="elpfjrprc4c1c"
            fill="#EAAE94"
            d="M463.63 248.26a21.09 21.09 0 0012.11-2.11s-3.46 6.2-12.37 4.45l.26-2.34z"
          ></path>
          <path
            id="elptzoqih1zx"
            fill="#263238"
            d="M459.89 221.07c.18.16 1.4-.3 3.07-.1 1.67.2 2.84.93 3 .8.16-.13-.08-.33-.59-.66a5.782 5.782 0 00-2.41-.82 5.172 5.172 0 00-2.46.26c-.5.21-.7.45-.61.52z"
          ></path>
          <path
            id="elk1m8x4s0dao"
            fill="#263238"
            d="M473.59 222.6c.18.16 1.4-.29 3.06-.09 1.66.2 2.83.92 3 .8.17-.12-.08-.33-.59-.66a6.094 6.094 0 00-2.4-.81 5.407 5.407 0 00-2.45.25c-.52.21-.71.44-.62.51z"
          ></path>
          <path
            id="el3i3zd674olg"
            fill="#263238"
            d="M473.28 224.82a.997.997 0 00.85 1.1 1 1 0 001.12-.79.995.995 0 00-.203-.721.998.998 0 00-1.767.411z"
          ></path>
          <path
            id="elnsz4wyhtwn"
            fill="#263238"
            d="M460.33 223.399a1.003 1.003 0 00.85 1.11 1.002 1.002 0 001.12-.79 1.002 1.002 0 00-.85-1.11 1.002 1.002 0 00-1.12.79z"
          ></path>
          <path
            id="el6nzmbn6jeyo"
            fill="#263238"
            d="M468 231.74a8.777 8.777 0 00-2.21-.64c-.35-.08-.68-.18-.71-.43.012-.362.134-.711.35-1 .42-.82.86-1.68 1.33-2.58a49.185 49.185 0 003.09-6.8c-.15-.08-1.78 2.85-3.63 6.53L465 229.4c-.273.401-.39.888-.33 1.37a.906.906 0 00.53.58c.19.073.388.123.59.15a9.08 9.08 0 002.21.24z"
          ></path>
          <path
            id="eldk7c78zaevq"
            fill="#263238"
            d="M475.26 216.55c.09.36 1.32.32 2.74.65 1.42.33 2.53.86 2.76.57.23-.29-.07-.49-.5-.87a4.625 4.625 0 00-2-1 4.453 4.453 0 00-2.2 0c-.54.24-.83.48-.8.65z"
          ></path>
          <path
            id="elh6ow00exi24"
            fill="#263238"
            d="M460.61 216.77c.11.3 1.33.21 2.74.42 1.41.21 2.56.6 2.76.34.2-.26-.1-.4-.56-.7a5.321 5.321 0 00-4.18-.58c-.53.16-.8.38-.76.52z"
          ></path>
          <path
            id="elhdmt6kz3tzb"
            fill="#F4D3BD"
            d="M488.32 228.27c.16-.05 6.39-1.19 5.55 5-.84 6.19-6.84 4-6.83 3.85.01-.15 1.28-8.85 1.28-8.85z"
          ></path>
          <path
            id="els2d4flnjw2m"
            fill="#EAAE94"
            d="M489.24 235s.09.09.26.2c.24.145.527.191.8.13a2.683 2.683 0 001.55-2.21 3.449 3.449 0 00-.09-1.58 1.227 1.227 0 00-.69-.93.556.556 0 00-.373-.016.558.558 0 00-.297.226c-.11.16-.08.29-.12.29-.04 0-.12-.12 0-.37a.716.716 0 01.29-.35.816.816 0 01.62-.07 1.515 1.515 0 011 1.1c.19.57.238 1.178.14 1.77a2.812 2.812 0 01-1.93 2.44 1.158 1.158 0 01-1-.3c-.17-.17-.19-.33-.16-.33z"
          ></path>
          <path
            id="el6d3jlcs14qw"
            fill="#263238"
            d="M457.35 213.59a13.085 13.085 0 0011.85-1.39c2.39-1.63 4.36-4.13 7.2-4.68a7.34 7.34 0 015.52 1.35 14.193 14.193 0 013.84 4.35c-2.22 4.4-1.59 9.64-.9 14.52.016.55.217 1.078.57 1.5a2.002 2.002 0 002.51-.28 7.684 7.684 0 002.1-3.85 25.513 25.513 0 001-10 17.35 17.35 0 00-3.83-9.21 16.417 16.417 0 00-11-5.54 20.149 20.149 0 00-12.09 2.94 11.868 11.868 0 00-4.24 4"
          ></path>
          <path
            id="el9ogchbkyg75"
            fill="#263238"
            d="M457.89 222.12c.58-2.48.85-5.61 1.43-8.09a15.1 15.1 0 0011.14-2.77c1.64-1.27 3.06-3 5.05-3.56 2.57-.74 5.23.61 7.56 1.92l-.09-.11a11.35 11.35 0 013 3.1 35.258 35.258 0 00-1.13 15 2.58 2.58 0 001.17 1.85c1.27.77 2.22-.1 3.18-.69.7-.44 1.7-.72 2.3-.15a35 35 0 004.06-10 2.522 2.522 0 004.44 2.22 5.31 5.31 0 01-1.22-2.52 3.27 3.27 0 002.403.794 3.251 3.251 0 002.247-1.164l-1.95-2 2.78.15a14.858 14.858 0 01-5.28-4.57 22.941 22.941 0 00-4.91-5c-2-1.28-4.64-1.8-6.68-.62.14-3.25-2.19-6.26-5.14-7.63a21.078 21.078 0 00-11.18-1.11c-2.46.52-5 2-8.21 4.31a29.922 29.922 0 00-4.26 3.68c-1.94 2.05-5.81 5.41-9.52 4.41l.33 3.82c2 1.91 6.13.23 6.13.23s-2.38 2.37-4.61 2.31l.6 3.28s3.37.59 4.85-2l1.51 4.91z"
          ></path>
          <path
            id="eliu8xzznjwr"
            fill="#455A64"
            d="M485.76 213.22s-.29-.48-.9-1.29a11.437 11.437 0 00-3.1-2.86 11.78 11.78 0 00-2.72-1 5.87 5.87 0 00-3.25-.08c-2.26.68-3.91 3-6.33 4.43a13.916 13.916 0 01-7.52 2.23 17.073 17.073 0 01-1.78-.08 1.65 1.65 0 00-.74 0 8.198 8.198 0 00-.71.35 6.78 6.78 0 00-1.9 2.13 5.376 5.376 0 01-3.7 2.19 6.121 6.121 0 01-1.18 0 1.395 1.395 0 01-.4-.07c0-.05.57 0 1.55-.15a5.295 5.295 0 003.45-2.21 6.876 6.876 0 012-2.28c.245-.142.499-.269.76-.38.297-.095.611-.122.92-.08.575.051 1.153.071 1.73.06a13.743 13.743 0 007.27-2.2 25.811 25.811 0 003.17-2.45 9.493 9.493 0 013.29-2 6.28 6.28 0 013.48.11c.974.261 1.912.645 2.79 1.14a10.778 10.778 0 013.08 3.06c.227.317.428.651.6 1 .11.29.16.42.14.43z"
          ></path>
          <path
            id="elt9wycncjhtf"
            fill="#455A64"
            d="M495.47 218.63s0 .19-.17.51a4.642 4.642 0 01-.78 1.28 2.389 2.389 0 01-2.14.87 1.683 1.683 0 01-1.21-.86 2.506 2.506 0 01-.27-1.55l.46.12a2 2 0 01-1.74 1.17 2.447 2.447 0 01-1.82-.68 3.804 3.804 0 01-1-1.49 10.033 10.033 0 01-.41-2.87 14.158 14.158 0 00-.33-2.57c.459.769.692 1.653.67 2.55.044.928.212 1.846.5 2.73.181.491.476.933.86 1.29a2.002 2.002 0 001.47.55 1.532 1.532 0 001.32-.86l.83-2.07-.37 2.19a1.613 1.613 0 001.08 1.94 2.1 2.1 0 001.84-.67 6.08 6.08 0 00.87-1.15c.21-.29.32-.44.34-.43z"
          ></path>
          <path
            id="elglpiikg1fa"
            fill="#455A64"
            d="M493.21 225.39a2.649 2.649 0 01-1.73.45 5.928 5.928 0 01-3.93-1.72 11.195 11.195 0 01-2.35-3.58 5.476 5.476 0 01-.58-1.66c.356.466.654.973.89 1.51a13.784 13.784 0 002.38 3.38 6.202 6.202 0 003.61 1.72c.572.004 1.143-.03 1.71-.1z"
          ></path>
          <path
            id="el9vifu56gj74"
            fill="#455A64"
            d="M492.92 226.539a3.25 3.25 0 01-1.52.11 8.256 8.256 0 01-3.49-1.18 8.125 8.125 0 01-2.64-2.58 3 3 0 01-.59-1.41c.323.382.617.786.88 1.21a9.836 9.836 0 002.6 2.36 9.68 9.68 0 003.29 1.25c.496.041.987.121 1.47.24z"
          ></path>
          <path
            id="elcmoj3q5llt8"
            fill="#455A64"
            d="M496.9 203.52a9.748 9.748 0 01-1.54-.21 10.31 10.31 0 00-3.65.22 10.452 10.452 0 00-3.34 1.51c-.77.54-1.2 1-1.26.91a3.57 3.57 0 011.05-1.19 8.864 8.864 0 013.43-1.71 8.584 8.584 0 013.82-.08 3.493 3.493 0 011.49.55z"
          ></path>
          <path
            id="el8ftapyvsfqu"
            fill="#455A64"
            d="M488.54 198.17a7.794 7.794 0 01-1.28 8.19 19.054 19.054 0 001.33-4c.142-1.394.125-2.8-.05-4.19z"
          ></path>
          <path
            id="elcxqftq9f4nt"
            fill="#455A64"
            d="M452.62 209.35c.383.321.82.572 1.29.74a7.128 7.128 0 004-.47c.82-.27 1.71-.63 2.68-1a17.217 17.217 0 002.93-1.38c2-1.14 4-2.77 6.47-3.88a11.3 11.3 0 013.84-1 7.078 7.078 0 013.63.75c2.11 1.1 3.6 2.62 5.2 3.25a5.174 5.174 0 003.93.18 4.234 4.234 0 001.19-.87s-.05.12-.21.34c-.25.294-.556.535-.9.71a5.279 5.279 0 01-4.14 0c-1.71-.64-3.25-2.16-5.27-3.17a6.677 6.677 0 00-3.4-.68c-1.271.122-2.513.46-3.67 1-2.41 1.07-4.41 2.68-6.45 3.82a16.79 16.79 0 01-3 1.38c-1 .34-1.86.69-2.7.94a7.005 7.005 0 01-4.15.32 2.707 2.707 0 01-1-.61 1.535 1.535 0 01-.27-.37z"
          ></path>
          <path
            id="elue0fwcqi2ig"
            fill="#455A64"
            d="M487.46 205.84a30.67 30.67 0 00-2.37-2.4 14.136 14.136 0 00-7.32-3.19 24.63 24.63 0 00-4.47.05c-1.236.119-2.48.146-3.72.08a8.118 8.118 0 01-2.46-.54 2.484 2.484 0 01-.81-.44c1.063.359 2.17.571 3.29.63a29.213 29.213 0 003.67-.18 22.067 22.067 0 014.56-.09 13.65 13.65 0 017.49 3.42 12.304 12.304 0 011.67 1.87c.196.238.355.504.47.79z"
          ></path>
          <path
            id="el479e84slxxp"
            fill="#263238"
            d="M468.71 236.22c0-.17 1.22-.31 2.26-1.28a6.88 6.88 0 001.13-1.44c.26-.41.45-.67.51-.63.06.04 0 .32-.2.79a4.392 4.392 0 01-1.1 1.64 3.725 3.725 0 01-1.77.92c-.54.1-.82.06-.83 0z"
          ></path>
          <path
            id="el07ni51d5kc6a"
            fill="#13CB6E"
            d="M629.62 408.06l16.38-9.28s14.93 13.53 18.13 12.11l-16.86-32.39-26.11 13.41 8.46 16.15z"
          ></path>
          <g id="elbanv38x4xv">
            <g id="Group_5" opacity="0.6">
              <path
                id="eln6m9krsgg1"
                fill="#fff"
                d="M639.38 389.5a1.503 1.503 0 011-1.7 1.444 1.444 0 011.7 1 1.56 1.56 0 01-1.08 1.8 1.477 1.477 0 01-1.64-1.24"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <g id="elwr9b6erity8">
            <g id="Group_6" opacity="0.6">
              <path
                id="elv2gt0a4ltt"
                fill="#fff"
                d="M647.27 378.5l-2.58 1.5 17.12 30.52s1.38 1.11 2.31.42l-16.85-32.44z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <path
            id="el4mrbz13f4zc"
            fill="#263238"
            d="M646.1 399.39c.16-.08-.18-.91-.08-1.95.1-1.04.55-1.81.42-1.92-.13-.11-.93.6-1 1.86-.07 1.26.56 2.12.66 2.01z"
          ></path>
          <path
            id="eluuir3zdp45"
            fill="#263238"
            d="M649.28 402.06c.17 0 .08-.87.47-1.75.39-.88 1-1.41.93-1.56-.07-.15-1 .21-1.5 1.32s-.06 2.07.1 1.99z"
          ></path>
          <path
            id="elk87t59o2ds"
            fill="#263238"
            d="M654.34 401.88c0-.17-.94 0-1.6.88-.66.88-.61 1.78-.44 1.78.17 0 .39-.7.94-1.4.55-.7 1.17-1.14 1.1-1.26z"
          ></path>
          <path
            id="elp31nzlgyzqh"
            fill="#263238"
            d="M642.14 401.1c.11-.15-.49-.76-.89-1.68-.4-.92-.49-1.77-.67-1.78-.18-.01-.39.95.1 2s1.37 1.6 1.46 1.46z"
          ></path>
          <g id="el8esvtifbi49">
            <g id="Group_7" opacity="0.3">
              <path
                id="elr9xudwj7rpg"
                fill="#000"
                d="M632.86 406.22l2.92-1.65-8.42-15.84-2.69 1.37 8.19 16.12z"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <path
            id="eljlsccvsw83h"
            fill="#263238"
            d="M638.8 402.91c.07 0-.16-.71-.85-1.62a8.48 8.48 0 00-1.41-1.43 8.383 8.383 0 00-1-.67 2.04 2.04 0 00-.63-.29.776.776 0 00-.88.36 1 1 0 000 .88c.101.21.236.403.4.57.265.302.556.579.87.83a6.29 6.29 0 001.76 1c.56.276 1.193.37 1.81.27 0-.1-.67-.21-1.64-.67a7.739 7.739 0 01-1.57-1 7.178 7.178 0 01-.77-.78c-.27-.29-.42-.58-.32-.75.1-.17.07-.08.2-.06.165.05.323.12.47.21.325.184.639.388.94.61.506.374.978.792 1.41 1.25.76.75 1.12 1.38 1.21 1.29z"
          ></path>
          <path
            id="ell1xsoat5eff"
            fill="#263238"
            d="M638.92 402.58a2.87 2.87 0 00-1.79.39 6.088 6.088 0 00-1.66 1.14 6.02 6.02 0 00-.77.93 1.506 1.506 0 00-.28 1.46.838.838 0 00.8.44 2.17 2.17 0 00.68-.14 4.186 4.186 0 002.41-2.13 2.359 2.359 0 00.3-1.81c-.11 0-.13.69-.68 1.6a4.292 4.292 0 01-1.26 1.3 3.629 3.629 0 01-.95.46c-.37.13-.72.15-.75 0a.99.99 0 01.24-.85 5.67 5.67 0 01.67-.85 6.575 6.575 0 011.45-1.16c.93-.54 1.61-.68 1.59-.78z"
          ></path>
          <path
            id="ely9d0tpr83hr"
            fill="#13CB6E"
            d="M583 433.57l18.57-3.05s9.34 17.86 12.82 17.63l-4.61-36.22-29.13 3.56 2.35 18.08z"
          ></path>
          <g id="el73rzxyyskf">
            <g id="Group_8" opacity="0.6">
              <path
                id="elw8kuohchk1"
                fill="#fff"
                d="M598.54 419.53a1.472 1.472 0 011.55-1.24 1.43 1.43 0 011.24 1.53 1.562 1.562 0 01-1.65 1.3 1.489 1.489 0 01-1.12-1.74"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <g id="ely3g07x8xc7f">
            <g id="Group_9" opacity="0.6">
              <path
                id="eluj4ksb7oar8"
                fill="#fff"
                d="M609.74 411.93l-2.92.47 5.51 34.6s.91 1.52 2 1.2l-4.59-36.27z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <path
            id="elt8o5ap9b8eg"
            fill="#263238"
            d="M601.42 431.14c.18 0 .15-.93.6-1.87.45-.94 1.15-1.5 1.06-1.66-.09-.16-1.08.24-1.62 1.4s-.19 2.18-.04 2.13z"
          ></path>
          <path
            id="el8qxe64xln1f"
            fill="#263238"
            d="M603.48 434.73c.18 0 .38-.78 1-1.48s1.44-1 1.41-1.14c-.03-.14-1-.16-1.86.72-.86.88-.72 1.92-.55 1.9z"
          ></path>
          <path
            id="elr4qpkikuusq"
            fill="#263238"
            d="M608.3 436.31c0-.16-.9-.3-1.81.28-.91.58-1.19 1.45-1 1.51.19.06.61-.52 1.36-1 .75-.48 1.46-.61 1.45-.79z"
          ></path>
          <path
            id="el3bnootzm76t"
            fill="#263238"
            d="M597.11 431.36c.16-.09-.19-.87-.25-1.88-.06-1.01.16-1.83 0-1.9-.16-.07-.7.75-.61 1.94.09 1.19.75 1.94.86 1.84z"
          ></path>
          <g id="elworyks6d">
            <g id="Group_10" opacity="0.3">
              <path
                id="elea4hfio6szw"
                fill="#000"
                d="M586.64 432.96l3.31-.53-2.43-17.78-3 .35 2.12 17.96z"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <path
            id="elcrnsgtszd7w"
            fill="#263238"
            d="M593.36 431.91a3.552 3.552 0 00-.24-1.82 8.643 8.643 0 00-.83-1.82 9.511 9.511 0 00-.68-1 2.347 2.347 0 00-.5-.49.77.77 0 00-1 0 1.001 1.001 0 00-.31.83c.031.23.091.455.18.67.144.375.322.737.53 1.08.359.572.8 1.087 1.31 1.53.438.446.998.752 1.61.88 0-.09-.55-.43-1.31-1.2a7.508 7.508 0 01-1.11-1.5 7.063 7.063 0 01-.46-1c-.15-.37-.19-.7 0-.82s.08-.05.21 0c.135.108.255.233.36.37.242.283.465.58.67.89.347.525.645 1.08.89 1.66.186.595.413 1.177.68 1.74z"
          ></path>
          <path
            id="elz3eyixaad3k"
            fill="#263238"
            d="M593.58 431.64a3.003 3.003 0 00-1.81-.25 5.993 5.993 0 00-2 .5c-.351.17-.685.37-1 .6a1.52 1.52 0 00-.77 1.27c.022.162.09.314.197.438a.844.844 0 00.403.262c.224.064.457.098.69.1.415.008.829-.042 1.23-.15a4.27 4.27 0 001.77-1c.85-.83 1-1.59.9-1.6-.1-.01-.35.61-1.19 1.27-.478.38-1.035.65-1.63.79a3.66 3.66 0 01-1 .1c-.39 0-.72-.11-.7-.26.02-.15.22-.52.51-.71.29-.221.602-.412.93-.57a6.872 6.872 0 011.76-.59c1.01-.2 1.7-.1 1.71-.2z"
          ></path>
          <path
            id="el13u1k0b10wn"
            fill="#263238"
            d="M506.73 333.38c-.58.23 8.79 9.25 10.3 15.36 1.81 7.37 6.75 15.8 6.75 15.8l34.62 33.16 65.2-9.54 11 20.09-78.6 21.31a14.305 14.305 0 01-12.27-2.32L493 389.54l-29.08-36 42.81-20.16z"
          ></path>
          <path
            id="elz5ye33rr4ii"
            fill="#263238"
            d="M459.1 356.28l4.2 15.44c11.78 24.34 34.22 70.43 40.16 73.4 8.26 4.13 84.23-11 84.23-11L586 413.18l-63.75 5.51-42.64-74.91-20.51 12.5z"
          ></path>
          <path
            id="elyutx12wzxl"
            fill="#455A64"
            d="M629.57 409.62s-.14.06-.43.15l-1.28.39-4.93 1.44-18.18 5.2-27 7.66-15.89 4.54c-1.39.38-2.76.8-4.19 1.16a19.832 19.832 0 01-13.26-1.45 21.833 21.833 0 01-3.87-2.44l-3.54-2.78a427.42 427.42 0 01-6.83-5.5c-4.42-3.66-8.65-7.24-12.59-10.75-7.92-7-14.8-13.56-20.4-19.25-5.6-5.69-9.88-10.54-12.68-14l-1.85-2.32c-.49-.7-.92-1.29-1.26-1.77l-.78-1.09c-.17-.25-.24-.38-.24-.38s.1.11.29.35c.19.24.46.61.81 1.06.35.45.79 1.06 1.3 1.74l1.88 2.29c2.84 3.44 7.15 8.25 12.78 13.9 5.63 5.65 12.57 12.17 20.45 19.13 4 3.49 8.17 7.07 12.6 10.72 2.21 1.83 4.49 3.65 6.82 5.49l3.54 2.73a20.229 20.229 0 003.79 2.4 19.401 19.401 0 0012.92 1.42c1.4-.35 2.8-.78 4.18-1.16l15.94-4.5 27-7.55 18.21-5.06 5-1.35 1.3-.34.39-.08z"
          ></path>
          <path
            id="elcfda75mngqu"
            fill="#455A64"
            d="M480.39 357.25c.05.06-.42.28-.51.95a1.3 1.3 0 00.29 1 1.158 1.158 0 001.192.167 1.151 1.151 0 00.688-.987 1.279 1.279 0 00-.55-.91c-.56-.39-1-.2-1.05-.28-.05-.08.08-.11.3-.17a1.36 1.36 0 01.93.16 1.538 1.538 0 01.82 1.16 1.568 1.568 0 01-1.856 1.584 1.573 1.573 0 01-.814-.424 1.578 1.578 0 01-.29-1.4c.076-.313.26-.589.52-.78.19-.09.32-.1.33-.07z"
          ></path>
          <path
            id="elrfxjyd2vap"
            fill="#455A64"
            d="M513.6 348.23a3 3 0 01-.57.61c-.573.504-1.185.962-1.83 1.37a15.802 15.802 0 01-7.15 2.36 15.568 15.568 0 01-7.41-1.24 12.235 12.235 0 01-2-1.07 2.753 2.753 0 01-.67-.52c.05-.08 1 .59 2.82 1.27a16.528 16.528 0 0014.25-1.1c1.63-.91 2.5-1.75 2.56-1.68z"
          ></path>
          <path
            id="el3w17qesc7ai"
            fill="#455A64"
            d="M572.44 292.87s-45.33-17-48.08-19.46c-2.75-2.46-14.72-16-19.7-17.75 0 0-8.08-2.12-13.58-1.81l-5.81.69v-4.41l-23.87.39-.66 5.88-4.07 1.54s-9.56 20-9.56 29.12c0 9.12-4.72 62.51 4.58 72.77 1.43 1.57 7 .56 7 .56s26.84-2.86 53.52-21.52l-9.77-11.29S499 304 503 287.06c.09-.37.17-.74.26-1.1 11.55 5.7 29.33 14 34.55 14 0 0 29.24 9.87 34.64 3.55.61-.71-.01-10.64-.01-10.64z"
          ></path>
          <path
            id="els4b85dtu8x8"
            fill="#455A64"
            d="M461.31 257.06s-9.35.37-15.44 7.94-16 39.86-16 39.86l-13.64-14-13 7.21s18.38 25.62 22 28.59a19.415 19.415 0 006.64 2.83c8.73.76 14.63-8.13 16.42-15.46l6-30.13"
          ></path>
          <path
            id="elil5nlf5ldtj"
            fill="#F4D3BD"
            d="M407.18 295.85l-14-8.31s-4.23-10-2.61-10.47c1.62-.47 3 5.57 3 5.57l2 1s-2.73-5.08-1.78-5.56c.95-.48 2.28 1 3 2.17.56.88 2.37 4.57 2.37 4.57l.94-1.3-.74-3.41s-1-2.28.11-2.3c1.11-.02 1.11-.1 1.94 1.79.83 1.89 1.4 3.3 1.4 3.3s4.08 2.42 4.95 2.15a5.112 5.112 0 014 .31s3.87 2 2.94 6.15"
          ></path>
          <path
            id="elfxk1tnwlfy9"
            fill="#263238"
            d="M501.88 258.37c.07 0-.3 1-.75 2.52a27.996 27.996 0 00-.69 12.47c.26 1.59.53 2.56.45 2.59a9.83 9.83 0 01-.8-2.52 23.785 23.785 0 01-.5-6.37 23.484 23.484 0 011.21-6.27 9.64 9.64 0 011.08-2.42z"
          ></path>
          <path
            id="elbjr28w1w5mk"
            fill="#263238"
            d="M464.41 257.13a9.387 9.387 0 01-.25-3.4 9.27 9.27 0 01.52-3.37 16.555 16.555 0 010 3.39 16.794 16.794 0 01-.27 3.38z"
          ></path>
          <path
            id="elv4zps8veler"
            fill="#263238"
            d="M470 258a14.674 14.674 0 01.08-3.82c.038-1.276.233-2.542.58-3.77a14.458 14.458 0 01-.09 3.82A15.315 15.315 0 01470 258z"
          ></path>
          <path
            id="elwhm6qqx8b4"
            fill="#263238"
            d="M477.44 257.77c-.14 0 .21-1.67.34-3.7.13-2.03 0-3.69.18-3.71a12.356 12.356 0 01-.52 7.41z"
          ></path>
          <path
            id="el40abjdof4eb"
            fill="#263238"
            d="M482.9 256.4c-.14 0 .08-1.41.05-3.11s-.29-3.07-.15-3.1c.489.953.711 2.021.64 3.09a6.198 6.198 0 01-.54 3.12z"
          ></path>
          <path
            id="el4zqf39rxjve"
            fill="#263238"
            d="M447.6 269.28c.13 0 0 13.06-.3 29.16s-.66 29.16-.8 29.16 0-13.06.31-29.17.65-29.15.79-29.15z"
          ></path>
          <path
            id="eltlk9y6n1qb"
            fill="#FAFAFA"
            d="M446.7 300a12.435 12.435 0 01-2-1.11 27.552 27.552 0 00-4.78-2.44 12.142 12.142 0 00-5.22-.76c-1.36.12-2.16.47-2.19.39a4.803 4.803 0 012.15-.74 11.156 11.156 0 015.43.65 21.448 21.448 0 014.8 2.61c.661.386 1.27.857 1.81 1.4z"
          ></path>
          <path
            id="elsv62fgsi2y"
            fill="#FAFAFA"
            d="M501.36 290.83a3.342 3.342 0 01-.47.3l-1.38.81c-.6.35-1.35.76-2.24 1.19-.89.43-1.88 1-3 1.44-1.12.44-2.38 1.05-3.76 1.55s-2.85 1-4.41 1.52a79.997 79.997 0 01-10.42 2.41c-3.53.527-7.091.821-10.66.88-1.65 0-3.21 0-4.67-.11s-2.82-.2-4.06-.33c-1.24-.13-2.34-.31-3.32-.46-.98-.15-1.81-.3-2.49-.45l-1.56-.35a2.064 2.064 0 01-.54-.15c.188-.008.376.009.56.05l1.58.26c.68.12 1.51.26 2.49.37.98.11 2.08.3 3.32.38 1.24.08 2.58.23 4 .27 1.42.04 3 .06 4.64.06a77.565 77.565 0 0021-3.26c1.56-.5 3.05-.94 4.4-1.47 1.35-.53 2.63-1 3.77-1.49 1.14-.49 2.15-1 3-1.37.85-.37 1.65-.79 2.27-1.11l1.42-.72c.165-.098.344-.172.53-.22z"
          ></path>
          <path
            id="ele1pqwywyzsm"
            fill="#FAFAFA"
            d="M527.67 276.35c.05.07-.8.63-2 1.7a25.828 25.828 0 00-4.26 4.68 26.658 26.658 0 00-3 5.6c-.58 1.52-.83 2.51-.91 2.49-.012-.24.015-.48.08-.71.127-.644.294-1.278.5-1.9a21.99 21.99 0 017.34-10.43 12.75 12.75 0 011.61-1.11c.4-.24.62-.35.64-.32z"
          ></path>
          <path
            id="el34hmkn9yyq7"
            fill="#FAFAFA"
            d="M532.74 277.91c0 .08-1 .54-2.47 1.51a21.915 21.915 0 00-8.53 10.65c-.63 1.67-.85 2.76-.93 2.74-.08-.02 0-.28.07-.78.125-.702.302-1.394.53-2.07a19.909 19.909 0 018.67-10.84c.611-.38 1.25-.714 1.91-1 .237-.109.49-.18.75-.21z"
          ></path>
          <path
            id="eld0efpgqphtv"
            fill="#FAFAFA"
            d="M501.72 286.72a2.285 2.285 0 01-.51.24l-1.47.6c-.64.26-1.43.57-2.36.88-.93.31-2 .72-3.17 1.07-2.36.76-5.21 1.53-8.4 2.26-3.19.73-6.75 1.33-10.5 1.78-3.75.45-7.36.62-10.63.68-3.27.06-6.23-.08-8.7-.28-1.23-.07-2.34-.21-3.32-.31s-1.82-.21-2.5-.32l-1.58-.24a1.996 1.996 0 01-.58-.08 2.1 2.1 0 01.56 0l1.58.15c.69.08 1.53.17 2.51.24.98.07 2.08.19 3.32.23 2.46.16 5.4.22 8.66.17 3.26-.05 6.84-.29 10.58-.71s7.27-1.06 10.46-1.73c3.19-.67 6-1.44 8.4-2.15 1.19-.33 2.24-.7 3.18-1 .94-.3 1.73-.57 2.38-.8l1.51-.51c.185-.083.38-.14.58-.17z"
          ></path>
          <path
            id="el6fqtmngaa2w"
            fill="#FAFAFA"
            d="M447.2 292.39a36.047 36.047 0 00-5.33-2.89A36.646 36.646 0 00436 288a5.05 5.05 0 011.81 0 16.62 16.62 0 014.25 1 16.28 16.28 0 013.82 2.12c.518.334.966.765 1.32 1.27z"
          ></path>
          <path
            id="elim1n0ktxebi"
            fill="#F4D3BD"
            d="M587.36 289.11l-9.9 3.32-5 .44v10.66l5.14 1.75 13.79-.75s7.9 4 9.91 3.76c2.01-.24 2-.86 2-.86l-9.77-5.91a5.605 5.605 0 013.76.5c2.25 1 6.14 3.26 7.52 2.64 1.38-.62 1.13-1.13 1.13-1.13l-7.65-4.82s6.55 2.94 7.67 2.44a1.994 1.994 0 001.23-1.48l-4.26-3.79-6.4-5-9.17-1.77z"
          ></path>
          <path
            id="el6tljbfjex1q"
            fill="#EAAE94"
            d="M581.86 293.5c.376.108.738.259 1.08.45.67.31 1.64.78 2.83 1.38 1.19.6 2.6 1.31 4.15 2.19.426.215.804.514 1.11.88.332.401.537.892.59 1.41a2.44 2.44 0 01-.34 1.56 2.291 2.291 0 01-1.38.89 4.633 4.633 0 01-2.19 0 15.76 15.76 0 01-1.91-.69c-1.2-.5-2.3-1-3.3-1.53l.26-.06a5.899 5.899 0 01-2.3 1.83c-.7.302-1.458.445-2.22.42a5.268 5.268 0 01-1.42-.24c-.31-.1-.47-.19-.46-.21.622.128 1.255.198 1.89.21a5.315 5.315 0 002.07-.49 5.764 5.764 0 002.11-1.77l.1-.14.15.08c1 .47 2.11 1 3.3 1.47.602.255 1.22.473 1.85.65a4.38 4.38 0 001.94 0 1.881 1.881 0 001.1-.69c.222-.373.317-.808.27-1.24a2.516 2.516 0 00-1.46-1.95c-1.5-.89-2.91-1.63-4.07-2.27l-2.76-1.52a4.984 4.984 0 01-.99-.62z"
          ></path>
          <path
            id="elbrz1ij31yi"
            fill="#EAAE94"
            d="M598.74 298.74a19.172 19.172 0 01-4.38-2.72 9.574 9.574 0 01-.89-.77h.3a6.204 6.204 0 01-2.77.75 2.172 2.172 0 01-1.14-.11c0-.07.42-.08 1.11-.2a7.414 7.414 0 002.58-.85l.17-.11.14.14c.25.24.55.48.85.73a45.435 45.435 0 014.03 3.14z"
          ></path>
          <path
            id="elrd6zm1v1qod"
            fill="#13CB6E"
            d="M615.63 375.37a20.462 20.462 0 01-5.9-.85c-19-5.69-18-19.4-17.23-30.42.74-10.27.77-16.73-7.7-19.91-11.21-4.2-15.82-.12-22.81 6.06-8.32 7.36-18.66 16.52-43.76 12.8-13-1.93-34-8.8-59.71-30.58a205.252 205.252 0 01-25.33-25.4l3.88-3.12c.39.48 38.78 47.79 81.89 54.17 22.8 3.38 31.79-4.58 39.73-11.6 7.12-6.3 13.84-12.25 27.86-7 12.38 4.65 11.61 15.42 10.92 24.93-.8 11.13-1.49 20.74 13.69 25.29 17.1 5.12 39.59-22 53-38.16 4.8-5.77 8.13-9.95 10.79-12.12l3.76 3c-1.66 2-6.28 7-10.72 12.31-13.66 16.49-33.68 40.6-52.36 40.6z"
          ></path>
          <path
            id="elyrfbbnnbct"
            fill="#fff"
            d="M545.14 336s-.2.19-.6.44c-.561.371-1.145.705-1.75 1a26.82 26.82 0 01-6.29 2.16 26.409 26.409 0 01-6.62.51 19.175 19.175 0 01-2-.21 2.513 2.513 0 01-.72-.17c0-.08 1.06 0 2.74 0a31.341 31.341 0 0012.75-2.64c1.53-.7 2.45-1.21 2.49-1.09z"
          ></path>
          <path
            id="elck3hbsnr2h7"
            fill="#fff"
            d="M574.38 320.91c-.544.02-1.089 0-1.63-.06a11.201 11.201 0 00-7.19 2.48c-.39.38-.808.731-1.25 1.05.23-.507.572-.955 1-1.31a9.552 9.552 0 017.44-2.56 3.627 3.627 0 011.63.4z"
          ></path>
          <path
            id="elechrj2lmato"
            fill="#fff"
            d="M606.23 368.14a6.545 6.545 0 01-2.25-.69 14.507 14.507 0 01-7.56-8.18 6.657 6.657 0 01-.51-2.29c.08 0 .28.85.84 2.16a16.467 16.467 0 003 4.64 16.197 16.197 0 004.39 3.35c1.26.66 2.12.93 2.09 1.01z"
          ></path>
          <path
            id="eliqvtn4kjpb"
            fill="#fff"
            d="M675.86 321.27a61.082 61.082 0 01-7.88 7.86 64.432 64.432 0 017.88-7.86z"
          ></path>
          <path
            id="elnrduzzovlt"
            fill="#13CB6E"
            d="M437.6 288.17a5.75 5.75 0 01-8.12.39l-8.78-8 7.74-8.51 8.77 8a5.748 5.748 0 01.39 8.12z"
          ></path>
          <path
            id="elcqkkllmbhii"
            fill="#263238"
            d="M437.6 288.17c.294-.432.565-.879.81-1.34a5.67 5.67 0 00.27-4.17 5.605 5.605 0 00-1.59-2.47l-2.65-2.37c-1.87-1.7-3.95-3.57-6.16-5.56h.34l-3.87 4.26-3.87 4.25v-.34L427 286l2.62 2.41a5.635 5.635 0 002.6 1.35 5.695 5.695 0 004.13-.67c.438-.289.859-.603 1.26-.94-.32.429-.717.795-1.17 1.08a5.72 5.72 0 01-4.28.83 5.914 5.914 0 01-2.79-1.38c-.83-.74-1.71-1.54-2.65-2.37l-6.17-5.57-.18-.16.17-.18 3.86-4.26 3.87-4.24.16-.18.18.16 6.12 5.61 2.62 2.41a5.836 5.836 0 011.64 2.65 5.727 5.727 0 01-.42 4.33 4.174 4.174 0 01-.97 1.29z"
          ></path>
          <path
            id="elhl1qnn6r95"
            fill="#13CB6E"
            d="M393.24 240.13l-15.14-12.52a2.793 2.793 0 01-.37-3.93 2.796 2.796 0 011.888-1 2.801 2.801 0 012.042.63l15.13 12.52-3.55 4.3z"
          ></path>
          <path
            id="el75t82fjtbbp"
            fill="#13CB6E"
            d="M379 258.42l-15.12-12.52a2.795 2.795 0 01-.896-2.97 2.79 2.79 0 012.404-1.964 2.78 2.78 0 012.042.634l15.14 12.51-3.57 4.31z"
          ></path>
          <g id="elfw0yzwn5et">
            <g id="Group_11" opacity="0.6">
              <path
                id="elu7tbelj1foq"
                fill="#fff"
                d="M393.24 240.13l-15.14-12.52a2.793 2.793 0 01-.37-3.93 2.796 2.796 0 011.888-1 2.801 2.801 0 012.042.63l15.13 12.52-3.55 4.3z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <g id="eli4f033wcyrd">
            <g id="Group_12" opacity="0.6">
              <path
                id="elmqglczut0g"
                fill="#fff"
                d="M379 258.42l-15.12-12.52a2.795 2.795 0 01-.896-2.97 2.79 2.79 0 012.404-1.964 2.78 2.78 0 012.042.634l15.14 12.51-3.57 4.31z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <path
            id="elzk9zshg80k"
            fill="#263238"
            d="M393.24 240.13c.069-.115.146-.226.23-.33l.68-.88 2.53-3.19v.21l-7.11-5.78-4.45-3.66-2.39-2-1.23-1a2.455 2.455 0 00-1.33-.58 2.52 2.52 0 00-2.24.86 2.574 2.574 0 00-.54 2.28c.096.372.281.715.54 1 .282.295.586.57.91.82l1.88 1.56 6.48 5.45 4.39 3.74 1.2 1c.153.122.297.256.43.4a2.696 2.696 0 01-.42-.3l-1.2-1c-1.11-.89-2.63-2.1-4.47-3.59l-6.66-5.41-1.92-1.57c-.351-.27-.684-.561-1-.87a3.007 3.007 0 01-.444-3.45 3.005 3.005 0 013.114-1.55 3.112 3.112 0 011.59.69l1.22 1 2.38 2 4.43 3.69 7 5.89.12.1-.1.11-2.71 3.16-.72.8c-.046.14-.11.275-.19.4z"
          ></path>
          <path
            id="el1azrk2c6b8n"
            fill="#263238"
            d="M379 258.42c.068-.119.148-.229.24-.33.18-.24.4-.53.68-.88l2.53-3.19v.21l-7.11-5.79-4.45-3.65-2.39-2-1.23-1a2.558 2.558 0 00-3.58.29 2.532 2.532 0 00-.53 2.28c.094.37.276.713.53 1 .286.292.594.563.92.81l1.87 1.57 6.49 5.45 4.39 3.74 1.2 1c.154.117.295.251.42.4 0 0-.14-.09-.41-.3l-1.21-.95-4.47-3.59-6.65-5.41-1.92-1.58a8.623 8.623 0 01-1-.87 2.9 2.9 0 01-.64-1.24 2.994 2.994 0 01.63-2.71 3.053 3.053 0 012.67-1 3.124 3.124 0 011.59.69c.41.35.82.68 1.23 1l2.38 2 4.43 3.68 7 5.9.11.1-.09.11c-1.14 1.31-2.05 2.37-2.72 3.15l-.71.81c-.1.22-.2.31-.2.3z"
          ></path>
          <path
            id="elaekyhfx6njf"
            fill="#13CB6E"
            d="M427.64 256l.4-.48a5.35 5.35 0 00-7.44-7.61l-22.16-18.46-25.35 30.33 22.68 18a5.35 5.35 0 001.09 7 5.34 5.34 0 007.44-.62 15.414 15.414 0 0021.31-2.67l3.64-4.54a15.42 15.42 0 00-1.61-20.95z"
          ></path>
          <path
            id="elpn4zkjozkgb"
            fill="#263238"
            d="M427.64 256l.19.17.53.51c.293.27.564.565.81.88l.49.6.5.73a14.597 14.597 0 012 4.17 15.41 15.41 0 01-2.23 13.33c-.81 1.15-1.75 2.24-2.67 3.43a29.277 29.277 0 01-3.07 3.53 15.392 15.392 0 01-9.26 4.1 15.653 15.653 0 01-10.73-3.06h.31a5.647 5.647 0 01-3.65 1.89 5.542 5.542 0 01-4.11-1.24 5.6 5.6 0 01-1.14-7.32l.05.31L378.07 264l-5.13-4.08-.2-.15.16-.2c9.24-11 17.87-21.34 25.38-30.3l.14-.17.16.14 22.11 18.51h-.17a5.441 5.441 0 015.781-.605 5.446 5.446 0 013.039 4.955 5.283 5.283 0 01-1 3c-.16.22-.334.43-.52.63l-.19.21s.24-.3.65-.88c.589-.89.902-1.934.9-3a5.282 5.282 0 00-2.23-4.29 5.13 5.13 0 00-3-1 5.223 5.223 0 00-3.31 1.13l-.09.07-.09-.07-22.2-18.4h.3l-25.28 30.4v-.35l5.12 4.08 17.55 14 .17.13-.12.18a5.127 5.127 0 001 6.71 5.12 5.12 0 007.13-.59l.14-.15.16.12A15.244 15.244 0 00423.9 283a29.927 29.927 0 003-3.48c.93-1.18 1.89-2.28 2.69-3.4a15.343 15.343 0 00.42-17.23c-.17-.26-.33-.51-.49-.74l-.48-.6c-.24-.324-.5-.631-.78-.92l-.51-.53a.576.576 0 01-.11-.1z"
          ></path>
          <g id="eln65y700m9ei">
            <path
              id="Vector"
              fill="#E0E0E0"
              d="M403.33 249.66h-20.29v3.97h20.29v-3.97z"
            ></path>
          </g>
          <path
            id="ellwzh6tw467s"
            fill="#263238"
            d="M427.64 256a8.56 8.56 0 01-.84 1.18c-.56.75-1.39 1.81-2.43 3.11-2.07 2.61-5 6.16-8.27 10.05-3.27 3.89-6.18 7.34-8.39 9.95l-2.54 3c-.3.383-.635.738-1 1.06a8.56 8.56 0 01.84-1.18c.56-.75 1.39-1.81 2.43-3.11 2.07-2.61 5-6.16 8.27-10l8.39-10 2.55-3c.296-.384.627-.739.99-1.06z"
          ></path>
          <path
            id="elof05s3fb8a"
            fill="#263238"
            d="M420.6 247.87c-.262.44-.563.855-.9 1.24-.61.78-1.51 1.9-2.62 3.27-2.24 2.74-5.38 6.48-8.88 10.58-3.5 4.1-6.64 7.74-9 10.49-1.12 1.28-2 2.35-2.74 3.16a9.433 9.433 0 01-1.06 1.12c.272-.435.577-.85.91-1.24.61-.78 1.5-1.9 2.62-3.27 2.24-2.74 5.38-6.48 8.88-10.58l9-10.48 2.74-3.17c.321-.4.672-.774 1.05-1.12z"
          ></path>
        </motion.g>
        <motion.g
          id="girl"
          animate={{ y: [4, 0, 4] }}
          transition={{ duration: 1.5, repeat: Infinity }}
        >
          <path
            id="el5f6zxn1a0en"
            fill="#B78876"
            d="M321 167.47c-.14.33-11.17 8.71-11.17 8.71l-.21 7.12 5.7.8 8.1-4.6 5.49-.28s3.35 1.77 4 2a1.941 1.941 0 002.25-.93l-5-3.64s5.54 3.09 6.07 2.68a1.719 1.719 0 00-.53-2.67s1.72.91 2.81.18.7-1.6.7-1.6l-7.32-6.57a5.002 5.002 0 00-3.41-1.28l-7.48.08z"
          ></path>
          <path
            id="eloebxiffa9q"
            fill="#AA6550"
            d="M321.88 170.56a4.76 4.76 0 01-.08.85c-.1.62-.22 1.4-.37 2.34l-.14-.17 4.21-.28a5.415 5.415 0 012.67.36c.469.237.853.615 1.1 1.08a1.81 1.81 0 01.07 1.63v.06h-.06c-1.33.79-3 .61-4.45.71a5.164 5.164 0 00-3.1 1.13 20.28 20.28 0 00-2.31 2.24 10.379 10.379 0 01-2.3 1.94 5.16 5.16 0 01-2.54.71 10.008 10.008 0 01-3.68-.77 6.103 6.103 0 01-1.24-.57c.446.09.884.217 1.31.38 1.156.409 2.374.616 3.6.61a4.92 4.92 0 002.34-.71 10.322 10.322 0 002.18-1.9 20.42 20.42 0 012.34-2.31 5.645 5.645 0 013.37-1.24c1.55-.09 3.09.05 4.24-.63l-.1.1a1.732 1.732 0 00-.94-2.1 4.916 4.916 0 00-2.45-.35l-4.22.16h-.18v-.17c.2-.93.37-1.7.5-2.32.043-.269.12-.531.23-.78z"
          ></path>
          <path
            id="eldwasoneftt"
            fill="#AA6550"
            d="M336.05 176.799a4.996 4.996 0 01-1.88-1.11c-1.08-.83-2.26-2.28-3.87-3.45a2.742 2.742 0 00-1.24-.52c-.44-.05-.91 0-1.35 0h-2.35a9.712 9.712 0 01-2.16-.14 8.962 8.962 0 012.16-.21l2.34-.09c.47-.03.94-.03 1.41 0a3.009 3.009 0 011.47.6 40.271 40.271 0 013.81 3.59c.97.86 1.7 1.26 1.66 1.33z"
          ></path>
          <path
            id="elf8qz1t8spo"
            fill="#13CB6E"
            d="M336.56 388.47l-.27 17.53s17.69 6.15 17.95 9.39l-33.93.69-.7-27.27 16.95-.34z"
          ></path>
          <g id="elgdlcpycigh9">
            <g id="Group_13" opacity="0.6">
              <path
                id="els7o1skv4u6"
                fill="#fff"
                d="M325.76 404.71a1.38 1.38 0 00-.92 1.6 1.33 1.33 0 001.58.93 1.458 1.458 0 001-1.7 1.388 1.388 0 00-1.75-.79"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <g id="el62ohdawurey">
            <g id="Group_14" opacity="0.6">
              <path
                id="elxih8r6wsuw8"
                fill="#fff"
                d="M320.31 416v-2.75l32.53.37s1.52.62 1.37 1.69l-33.9.69z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <path
            id="elohr7rs0gsob"
            fill="#263238"
            d="M336.83 405.78c0 .16-.82.26-1.63.81-.81.55-1.22 1.25-1.38 1.19-.16-.06.08-1 1.06-1.67.98-.67 1.99-.48 1.95-.33z"
          ></path>
          <path
            id="elyskza1dc1in"
            fill="#263238"
            d="M340.42 407.18c.05.16-.67.45-1.22 1.17-.55.72-.69 1.45-.86 1.45-.17 0-.29-.94.41-1.81.7-.87 1.67-.99 1.67-.81z"
          ></path>
          <path
            id="elp761te5tjeg"
            fill="#263238"
            d="M342.53 411.399c-.15 0-.39-.79 0-1.7.39-.91 1.18-1.3 1.25-1.16.07.14-.4.63-.72 1.39-.32.76-.36 1.45-.53 1.47z"
          ></path>
          <path
            id="elzei14gg977"
            fill="#263238"
            d="M336.45 401.79c-.06.15-.83-.07-1.76 0s-1.66.39-1.75.25c-.09-.14.6-.751 1.7-.831 1.1-.08 1.89.441 1.81.581z"
          ></path>
          <g id="el97vqxfgwv5c">
            <g id="Group_15" opacity="0.3">
              <path
                id="elhv13afl75n"
                fill="#000"
                d="M336.5 391.93l-.04 3.12-16.69.19-.08-2.81 16.81-.5z"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <path
            id="eliwhimpsu7u"
            fill="#263238"
            d="M336.44 398.26a3.31 3.31 0 01-1.7 0 7.84 7.84 0 01-1.79-.51 8.328 8.328 0 01-1-.5 1.716 1.716 0 01-.52-.39.73.73 0 01-.1-.88.928.928 0 01.72-.39c.216-.007.432.02.64.08a5.999 5.999 0 012.65 1.34c.8.72 1.09 1.33 1 1.36-.09.03-.47-.45-1.28-1a6.66 6.66 0 00-1.54-.82 5.604 5.604 0 00-1-.28c-.36-.09-.66-.08-.75.08-.09.16 0 .08 0 .19.116.107.244.2.38.28.31.19.61.35.91.5.533.25 1.089.448 1.66.59.582.07 1.157.187 1.72.35z"
          ></path>
          <path
            id="el6sph1x0nes9"
            fill="#263238"
            d="M336.23 398.499a2.707 2.707 0 01-.48-1.64 5.51 5.51 0 01.19-1.86c.108-.346.248-.681.42-1a1.387 1.387 0 011.06-.88.785.785 0 01.72.46c.097.195.161.405.19.62.06.378.06.763 0 1.14a3.796 3.796 0 01-.69 1.77c-.64.9-1.33 1.11-1.34 1.05-.01-.06.51-.41 1-1.27a3.985 3.985 0 00.51-1.61 3.648 3.648 0 00-.06-1c-.06-.36-.2-.64-.33-.61-.13.03-.45.28-.59.57-.157.3-.29.611-.4.93a5.988 5.988 0 00-.29 1.71c-.01.97.18 1.59.09 1.62z"
          ></path>
          <path
            id="elsbbi9nbwpan"
            fill="#263238"
            d="M274.8 289.57c.83 1.25 37.67 39.47 37.67 39.47s-4.58 17.61-2.93 32.92c1.65 15.31 6.62 39.71 6.62 39.71l26.71-2.13-7.27-83L306.44 278l-31.64 11.57z"
          ></path>
          <path
            id="elxsqwrf4dk6"
            fill="#13CB6E"
            d="M345.79 369.13l3.28 17.19s18.57 2.44 19.49 5.56l-33.1 7.55-6.2-26.57 16.53-3.73z"
          ></path>
          <g id="ell27atkp6ez">
            <g id="Group_16" opacity="0.6">
              <path
                id="el9wxmrnrpfmr"
                fill="#fff"
                d="M338.51 387.22a1.378 1.378 0 00-.58 1.76 1.329 1.329 0 001.73.59 1.463 1.463 0 00.61-1.86 1.372 1.372 0 00-1.87-.42"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <g id="elt5sxy7iav2a">
            <g id="Group_17" opacity="0.6">
              <path
                id="el1xjb5a5nvtj"
                fill="#fff"
                d="M335.46 399.43l-.52-2.7 31.93-6.23s1.62.3 1.69 1.38l-33.1 7.55z"
                opacity="0.6"
              ></path>
            </g>
          </g>
          <path
            id="elusra4fehli"
            fill="#263238"
            d="M349.57 386c0 .16-.76.42-1.44 1.12-.68.7-.94 1.48-1.1 1.45-.16-.03-.14-1 .69-1.86.83-.86 1.85-.84 1.85-.71z"
          ></path>
          <path
            id="elp84wxfcwpp"
            fill="#263238"
            d="M353.37 386.67c.07.15-.57.59-1 1.39-.43.8-.39 1.57-.55 1.6-.16.03-.47-.86 0-1.86s1.51-1.28 1.55-1.13z"
          ></path>
          <path
            id="el0tcn6t7k2pfg"
            fill="#263238"
            d="M356.29 390.379c-.14.07-.55-.69-.34-1.67.21-.98.89-1.5 1-1.39.11.11-.26.7-.42 1.51-.16.81-.09 1.49-.24 1.55z"
          ></path>
          <path
            id="el3h3p9sbwfb2"
            fill="#263238"
            d="M348.39 382.199c0 .16-.83.1-1.73.38s-1.54.72-1.66.6c-.12-.12.44-.86 1.5-1.16 1.06-.3 1.94.03 1.89.18z"
          ></path>
          <g id="elynbnxotsoh">
            <g id="Group_18" opacity="0.3">
              <path
                id="ely78fcpo2x1o"
                fill="#000"
                d="M346.44 372.54l.59 3.06-16.3 3.57-.65-2.74 16.36-3.89z"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <path
            id="elmjoyeji13qa"
            fill="#263238"
            d="M347.66 378.74a3.154 3.154 0 01-1.66.37 8.223 8.223 0 01-2.92-.42 2.234 2.234 0 01-.59-.28.708.708 0 01-.27-.84.893.893 0 01.62-.53c.213-.052.432-.072.65-.06.373.009.744.049 1.11.12a5.92 5.92 0 011.75.65c.93.54 1.33 1.08 1.29 1.13-.04.05-.55-.35-1.47-.76a6.769 6.769 0 00-1.67-.5 5.582 5.582 0 00-1-.07c-.37 0-.67.05-.72.23-.05.18 0 .09.08.17.132.088.277.156.43.2.325.125.66.225 1 .3.57.142 1.153.226 1.74.25a11.52 11.52 0 011.63.04z"
          ></path>
          <path
            id="elqsetw6uztpc"
            fill="#263238"
            d="M347.51 379c-.06 0-.51-.47-.81-1.5a5.897 5.897 0 01-.19-1.87c.03-.372.097-.741.2-1.1a1.413 1.413 0 01.86-1.08.796.796 0 01.8.31c.132.173.236.365.31.57.142.355.23.729.26 1.11a4.003 4.003 0 01-.32 1.88c-.45 1-1.08 1.35-1.11 1.3.272-.469.515-.953.73-1.45a4.051 4.051 0 00.17-1.68 3.72 3.72 0 00-.25-.95c-.13-.34-.33-.59-.45-.53-.12.06-.39.37-.46.68a5.646 5.646 0 00-.15 2.72c.18 1 .49 1.59.41 1.59z"
          ></path>
          <path
            id="elznb5mmdyen"
            fill="#263238"
            d="M208.9 263.42s-2.47 15.81 7.11 26.15c9.58 10.34 19.91 14.89 38.11 14.07 18.2-.82 57.49.09 57.49.09s1.66 21 6.21 38.17 13.63 43 13.63 43l17.8-1.7s-4.81-93.58-6.05-101.85c-1.24-8.27-15-12.46-22.49-14.94-7.49-2.48-50.16-11.05-50.16-11.05l-38.77-5.44-22.88 13.5z"
          ></path>
          <path
            id="el86477p9codc"
            fill="#455A64"
            d="M349.25 383.16h-.31l-.93.07-3.62.26-13.63.9h-.12v-.12c-2.39-7.66-5.49-17.61-9.08-29.09-1.79-5.75-3.69-11.89-5.69-18.31-1-3.2-2.07-6.49-2.8-9.93a66.939 66.939 0 01-1.49-10.6 50.684 50.684 0 01.67-12.5l.23.3c-10.85-.22-21.07-.33-30.29-.36-4.61 0-9 0-13 .07s-7.84.21-11.29.21c-6.88 0-12.39-.35-16.18-.68-1.89-.16-3.36-.32-4.34-.43l-1.13-.15-.38-.06c.13-.011.26-.011.39 0l1.12.1 4.35.35c3.79.27 9.3.58 16.17.52 3.43 0 7.21-.2 11.28-.27 4.07-.07 8.44-.11 13-.11 9.23 0 19.45.09 30.3.31h.29l-.06.3a50.796 50.796 0 00-.65 12.35 67.235 67.235 0 001.47 10.53c.74 3.41 1.8 6.67 2.79 9.88 2 6.43 3.87 12.57 5.65 18.32 3.53 11.51 6.59 21.47 8.95 29.15l-.15-.11 13.64-.76 3.62-.18h.93c.098.004.195.017.29.04z"
          ></path>
          <path
            id="elzs90x0rwpco"
            fill="#455A64"
            d="M321 293c.1.1-2.11 2.24-4.37 5.25-2.26 3.01-3.7 5.73-3.84 5.66a7.294 7.294 0 01.82-1.85 31.005 31.005 0 012.63-4.1 31.727 31.727 0 013.21-3.67A6.36 6.36 0 01321 293z"
          ></path>
          <path
            id="eljgyk2nynhdl"
            fill="#455A64"
            d="M325.83 294.68a38.98 38.98 0 00-7 3.58 39.807 39.807 0 00-5.84 5.32 8.43 8.43 0 011.35-1.92 21.991 21.991 0 019.26-6.4 7.724 7.724 0 012.23-.58z"
          ></path>
          <path
            id="el8o66rneib1b"
            fill="#455A64"
            d="M246.39 168.489c1.31.65 28.62 17.35 29.92 17.35 1.3 0 33.61-11.79 33.61-11.79l6.62 10.22s-28.33 21.82-37.61 22.14c-9.28.32-24-7.13-24-7.13l-8.54-30.79z"
          ></path>
          <path
            id="elld1h5jwzc4"
            fill="#455A64"
            d="M189.57 177.66S155.58 212.22 156 221c.42 8.78 4.64 13.63 7.34 14.77 3.77 1.6 46.33-3.64 46.33-3.64l2.52-16.56c-7-1.39-15.87-2.44-25.48-3.36 4.31-4.51 9.1-7.93 15-8.93l10.18-11.07s-12.72-16.71-22.32-14.55z"
          ></path>
          <path
            id="eltktss6icge"
            fill="#455A64"
            d="M187.81 187.999s-1.63-7.46 2-10.45c6-5 11.12-7.27 15.48-9.44 5-2.49 10.29-5.37 10.29-5.37l30.85 5.74s16.47 26.3 16.12 32.77a17.168 17.168 0 01-4.45 10.54 39.77 39.77 0 002.25 21.1c3.69 9.17 10.24 22.42 10.24 22.42s-35.36-5.74-52.13 2.47c-9 4.4-9.81 11.41-9.81 11.41l-1.31-38.52-6.93-23.59-12.6-19.08z"
          ></path>
          <path
            id="elylfjtnhp0ip"
            fill="#263238"
            d="M211.18 215.36s-.16-.06-.47-.12c-.31-.06-.76-.15-1.36-.24-1.2-.18-2.93-.39-5.08-.64-4.3-.49-10.25-1.09-16.82-1.72l-1.13-.11-.61-.06.48-.38c2.49-2 4.83-3.84 7-5.53a17.547 17.547 0 016.65-2.93c1.599-.34 3.24-.448 4.87-.32.44.036.877.096 1.31.18.151.015.3.052.44.11a21.097 21.097 0 00-6.55.36 17.548 17.548 0 00-6.45 3l-7 5.56-.13-.44 1.14.11c6.57.63 12.52 1.29 16.81 1.87 4.29.58 6.97 1.08 6.9 1.3z"
          ></path>
          <path
            id="elx3welgo1gbn"
            fill="#263238"
            d="M174.66 212.999a4.006 4.006 0 011.38-1.22 8.882 8.882 0 014.25-1.35 8.62 8.62 0 014.36.91 4.01 4.01 0 011.5 1.07c-.05.07-.62-.33-1.65-.76a9.791 9.791 0 00-4.19-.73 9.686 9.686 0 00-4.09 1.16c-.97.49-1.51.92-1.56.92z"
          ></path>
          <path
            id="el595yjw3yvox"
            fill="#263238"
            d="M207 191.859c-.09 0-.39-1.26-1.13-3.24a22.438 22.438 0 00-4.14-7 14.118 14.118 0 00-3.5-2.77l-.9-.45c-.29-.13-.6-.22-.89-.32a9.578 9.578 0 00-1.65-.43 12.399 12.399 0 00-2.47-.2 3.531 3.531 0 01-.93 0c.297-.099.607-.149.92-.15a10.417 10.417 0 014.26.43c.3.1.62.18.93.31l1 .45a14.013 14.013 0 013.65 2.84 20.817 20.817 0 014.1 7.25c.274.798.494 1.613.66 2.44.062.276.093.558.09.84z"
          ></path>
          <path
            id="el6vjlfwe5r2v"
            fill="#263238"
            d="M257.13 212.79s.41-.46 1.08-1.34a15.616 15.616 0 002.29-4 16.82 16.82 0 001.18-6.73 15.741 15.741 0 00-2.45-7.94 60.847 60.847 0 00-5.17-6.73 30.905 30.905 0 01-3.95-5.78 14.002 14.002 0 01-1.45-4.49 8.431 8.431 0 01-.08-1.28 1.36 1.36 0 010-.45s0 .62.24 1.7a14.862 14.862 0 001.57 4.36 31.369 31.369 0 004 5.65 57.584 57.584 0 015.24 6.76 15.94 15.94 0 012.48 8.19 16.596 16.596 0 01-1.31 6.87 14.662 14.662 0 01-2.46 4c-.272.332-.57.643-.89.93-.095.106-.202.2-.32.28z"
          ></path>
          <g id="el8rex31kwuwh">
            <g id="Group_19" opacity="0.3">
              <path
                id="elsqqqnxzn1qk"
                fill="#000"
                d="M203.38 213.999a4.622 4.622 0 012.83-5.63 24.257 24.257 0 013.72-.48c.78-.13 1.65-.52 1.81-1.3.2-1-.81-1.72-1.71-2.11a15.758 15.758 0 00-11.67-.28 18.542 18.542 0 013.65 9.66"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <g id="elbwbp0w7bkre">
            <g id="Group_20" opacity="0.3">
              <path
                id="el8wyj33jsv2n"
                fill="#000"
                d="M250.14 179.76c-1.59-3.19 4.18 5.77 6.87 8.11a59.213 59.213 0 017.76 7.35c2.23 2.78 3.88 5.86 3.57 9.42l-6.42-2.26s.81-5.19-2-9c-2.81-3.81-9.14-12.38-9.78-13.62z"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <g id="elf0agrc02ezn">
            <g id="Group_21" opacity="0.3">
              <path
                id="elfzkq5wdlbgg"
                fill="#000"
                d="M186.21 212c-1.77-1.92-4.48-2.7-7.08-3a10.885 10.885 0 00-4.72.31 5.087 5.087 0 00-3.32 3.17 1.517 1.517 0 00.12 1.38 1.325 1.325 0 001.46.34 5.092 5.092 0 001.37-.84 12.183 12.183 0 015.812-2.216 12.182 12.182 0 016.148.946"
                opacity="0.3"
              ></path>
            </g>
          </g>
          <path
            id="el8ty3zd7vb6x"
            fill="#263238"
            d="M196.72 187.46a2.112 2.112 0 01-1.305 1.95 2.106 2.106 0 01-2.301-.462 2.115 2.115 0 01.331-3.248c.348-.23.757-.352 1.175-.35a2.1 2.1 0 012.1 2.11z"
          ></path>
          <path
            id="elzl8wz483tq"
            fill="#263238"
            d="M208.05 173.52a2.11 2.11 0 11-4.218-.001 2.11 2.11 0 014.218.001z"
          ></path>
          <path
            id="elpqdot2ys4gs"
            fill="#263238"
            d="M230.11 174.39a2.11 2.11 0 11-2.1-2.1 2.1 2.1 0 012.1 2.1z"
          ></path>
          <path
            id="elh57w8uwzw7o"
            fill="#263238"
            d="M247.17 173.07a2.11 2.11 0 11-4.218-.001 2.11 2.11 0 014.218.001z"
          ></path>
          <path
            id="elxua9ngtbw9"
            fill="#263238"
            d="M275.39 198.049a2.113 2.113 0 01-1.305 1.951 2.114 2.114 0 01-2.301-.462 2.114 2.114 0 01.331-3.248 2.114 2.114 0 013.118.953c.105.256.158.53.157.806z"
          ></path>
          <path
            id="eld5n53pzksrt"
            fill="#263238"
            d="M258.17 195.94a2.106 2.106 0 01-1.296 1.956 2.104 2.104 0 01-2.302-.451 2.105 2.105 0 01-.462-2.3 2.109 2.109 0 011.95-1.305 2.105 2.105 0 011.491.611 2.104 2.104 0 01.619 1.489z"
          ></path>
          <path
            id="elhm8hn5sz4u6"
            fill="#263238"
            d="M261.8 181.929a2.116 2.116 0 01-1.305 1.951 2.114 2.114 0 01-2.301-.462 2.114 2.114 0 01.331-3.248 2.106 2.106 0 011.175-.351 2.104 2.104 0 011.943 1.304c.105.256.158.53.157.806z"
          ></path>
          <path
            id="el4yw5p2aw7h3"
            fill="#263238"
            d="M285.48 189.77a2.11 2.11 0 100-4.22 2.11 2.11 0 000 4.22z"
          ></path>
          <path
            id="elkkbmb66mncq"
            fill="#263238"
            d="M302.19 189.56a2.112 2.112 0 01-2.519 2.08 2.11 2.11 0 112.519-2.08z"
          ></path>
          <path
            id="eldf8mr1qblsg"
            fill="#263238"
            d="M249.36 212.1a2.106 2.106 0 01-1.296 1.957 2.112 2.112 0 01-2.883-1.532 2.106 2.106 0 01.896-2.169 2.11 2.11 0 011.173-.356 2.105 2.105 0 011.491.612 2.1 2.1 0 01.619 1.488z"
          ></path>
          <path
            id="el0hou0oeghm6"
            fill="#263238"
            d="M257 224.06a2.106 2.106 0 01-1.296 1.956 2.104 2.104 0 01-2.302-.45 2.112 2.112 0 01.315-3.25 2.11 2.11 0 011.173-.356 2.105 2.105 0 011.491.612 2.1 2.1 0 01.619 1.488z"
          ></path>
          <path
            id="elixvo3dkqcu"
            fill="#263238"
            d="M241.29 232.11a2.11 2.11 0 11-2.1-2.11 2.104 2.104 0 011.943 1.303c.105.256.158.53.157.807z"
          ></path>
          <path
            id="elsvry4l2gajj"
            fill="#263238"
            d="M231.1 245.339a2.11 2.11 0 11-2.1-2.1 2.104 2.104 0 012.1 2.1z"
          ></path>
          <path
            id="eltfk1ayvv51e"
            fill="#263238"
            d="M256.06 243.1a2.113 2.113 0 01-2.534 2.076A2.109 2.109 0 01254 241a2.096 2.096 0 012.06 2.1z"
          ></path>
          <path
            id="elcytft6vcyg7"
            fill="#263238"
            d="M215.22 255a2.112 2.112 0 01-1.305 1.95 2.106 2.106 0 01-2.301-.462 2.115 2.115 0 01.331-3.248c.348-.23.757-.352 1.175-.35a2.1 2.1 0 012.1 2.11z"
          ></path>
          <path
            id="el6phmpwmj019"
            fill="#263238"
            d="M224 222a2.112 2.112 0 01-1.305 1.95 2.106 2.106 0 01-2.301-.462 2.115 2.115 0 01.331-3.248c.348-.23.757-.352 1.175-.35a2.103 2.103 0 011.488.618A2.088 2.088 0 01224 222z"
          ></path>
          <path
            id="eldlvv3du6x4d"
            fill="#263238"
            d="M216.92 237.33a2.11 2.11 0 11-4.218-.001 2.11 2.11 0 014.218.001z"
          ></path>
          <path
            id="elfgzme5z3yem"
            fill="#263238"
            d="M242.62 190.37a2.11 2.11 0 11-2.1-2.11 2.097 2.097 0 012.1 2.11z"
          ></path>
          <path
            id="ell3e5y2gu4g"
            fill="#263238"
            d="M229.49 207.19a2.11 2.11 0 11-2.11-2.11 2.101 2.101 0 012.11 2.11z"
          ></path>
          <path
            id="elhqzd4g1aqyr"
            fill="#263238"
            d="M216.92 193.839a2.11 2.11 0 11-4.22 0 2.11 2.11 0 014.22 0z"
          ></path>
          <path
            id="el0lxsuq1oqskj"
            fill="#263238"
            d="M165 226.43a2.106 2.106 0 01-1.296 1.957 2.112 2.112 0 01-2.883-1.532 2.106 2.106 0 01.896-2.169 2.11 2.11 0 011.173-.356 2.123 2.123 0 012.11 2.1z"
          ></path>
          <path
            id="elpkl30azn6yj"
            fill="#263238"
            d="M174.76 199.79a2.112 2.112 0 01-1.305 1.95 2.106 2.106 0 01-2.301-.462 2.115 2.115 0 01.331-3.248c.348-.23.757-.352 1.175-.35a2.097 2.097 0 012.1 2.11z"
          ></path>
          <path
            id="elz26rfktc4t"
            fill="#263238"
            d="M193.48 229.41a2.11 2.11 0 11-4.222.019 2.11 2.11 0 014.222-.019z"
          ></path>
          <path
            id="elfpd88oj17c7"
            fill="#263238"
            d="M206.31 219.57a2.112 2.112 0 01-2.519 2.08 2.117 2.117 0 01-1.661-1.661 2.11 2.11 0 114.18-.419z"
          ></path>
          <path
            id="elmg3x8r5d4js"
            fill="#263238"
            d="M180.7 217.29a2.112 2.112 0 01-1.305 1.95 2.106 2.106 0 01-2.301-.462 2.115 2.115 0 01.331-3.248c.348-.23.757-.352 1.175-.35a2.097 2.097 0 012.1 2.11z"
          ></path>
          <path
            id="el7raa7162xk3"
            fill="#263238"
            d="M191.65 203.37a2.11 2.11 0 11-2.1-2.11 2.097 2.097 0 012.1 2.11z"
          ></path>
          <path
            id="elv3vlndcofqp"
            fill="#13CB6E"
            d="M182.78 324.93a60.51 60.51 0 01-12.93-1.4c-8.82-1.94-15.73-4.79-21-8.69a66.732 66.732 0 01-20.45 3.71c-22.68.61-43.21-6.48-68.38-26.85a139.288 139.288 0 01-3.66-8.59c24.23 19.61 50.46 31 71.91 30.46a62.507 62.507 0 0016.17-2.57c-5.85-6.1-8.8-14.07-8.76-23.75 0-10.61 4.56-15.42 8.34-17.58 5.35-3.07 12.67-2.65 19.09 1.09 7.08 4.13 11.29 11.11 11.26 18.68a20.517 20.517 0 01-8.08 16 61.51 61.51 0 01-11.89 7.15 59.18 59.18 0 0039.6 6.24c21.31-4.23 39.11-11.79 52.9-22.47a85.912 85.912 0 0033.52-70.76v-.09c-1.23-24.65-15.62-48.73-36.68-61.35-18.26-10.95-41.66-13.95-65.88-8.45-15.05 3.42-35.53 11.53-47.6 30.76-13 20.72-9.48 46.43 1.25 58.38 7.65 8.53 22.31 5.45 34.1 3l3.88-.8c12.67-2.52 64.08-26.63 64.28-26.7l1.09 4.1c-.83.29-51.38 24.9-64.4 27.49-1.24.24-2.52.51-3.83.79-13 2.73-29.26 6.13-38.83-4.53-6.92-7.72-10.77-18.86-10.84-31.37a62.995 62.995 0 019.08-33c13-20.67 34.75-29.34 50.72-33 25.47-5.78 50.17-2.58 69.54 9 22.4 13.5 37.7 39.17 39.06 65.39.11 1.34 1.45 20.46-8.78 42.1a90.267 90.267 0 01-26.66 33c-14.41 11.16-32.9 19-55 23.42a62.086 62.086 0 01-12.14 1.19zm-30.71-52.31a11.192 11.192 0 00-5.58 1.39c-3.79 2.17-5.8 6.76-5.83 13.28 0 9.33 2.89 16.54 9 21.9a56.74 56.74 0 0013.56-7.7 15.539 15.539 0 006.15-12.05c0-5.77-3.26-11.13-8.79-14.35a17.139 17.139 0 00-8.51-2.47z"
          ></path>
          <path
            id="ely70kmpa9hr9"
            fill="#263238"
            d="M152.83 317.419a5.783 5.783 0 01-1.87-.91 33.97 33.97 0 01-4.05-3 31.41 31.41 0 01-3.61-3.5 5.405 5.405 0 01-1.17-1.73c.06 0 .53.6 1.43 1.5.9.9 2.18 2.1 3.66 3.35s2.89 2.33 3.93 3.06c.588.37 1.15.781 1.68 1.23z"
          ></path>
          <path
            id="elabmbvztaqdv"
            fill="#263238"
            d="M158.07 314.5a6.076 6.076 0 01-2.25-.57 18.942 18.942 0 01-4.8-2.87 23.946 23.946 0 01-3.91-4 7.353 7.353 0 01-1.22-2c.06 0 .56.68 1.49 1.75a30.61 30.61 0 004 3.82 22.886 22.886 0 004.63 2.94c1.22.64 2.08.85 2.06.93z"
          ></path>
          <path
            id="eltvorulnfh5h"
            fill="#fff"
            d="M138.11 297.27a2.554 2.554 0 01-.32-.81 22.826 22.826 0 01-.58-2.3 29.65 29.65 0 011.09-15.44c.253-.747.55-1.479.89-2.19a3.1 3.1 0 01.42-.76c.08 0-.41 1.17-1 3.06a35.198 35.198 0 00-1.41 7.58 34.603 34.603 0 00.34 7.69c.32 1.96.65 3.15.57 3.17z"
          ></path>
          <path
            id="elqjg6u180ri"
            fill="#fff"
            d="M125.66 315.17a8.625 8.625 0 01-2.41.42 26.292 26.292 0 01-11.61-1.65 9 9 0 01-2.2-1.07c.788.197 1.563.444 2.32.74a33.312 33.312 0 005.66 1.32 34 34 0 005.81.31 20.317 20.317 0 012.43-.07z"
          ></path>
          <path
            id="elm0ot34u4ga9"
            fill="#fff"
            d="M263.53 282a12.01 12.01 0 01-1.46 2.18 53.014 53.014 0 01-8.82 9.05 11.467 11.467 0 01-2.14 1.51A17.682 17.682 0 01253 293c1.2-1.07 2.83-2.58 4.55-4.33 1.72-1.75 3.18-3.44 4.22-4.67a18.17 18.17 0 011.76-2z"
          ></path>
          <path
            id="ellwp40auxrzr"
            fill="#fff"
            d="M120.9 231.089a6.676 6.676 0 01-.51-1.33 35.056 35.056 0 01-1-3.73 48.86 48.86 0 01-1-5.66c-.524-4.678-.4-9.406.37-14.05a49.01 49.01 0 011.28-5.61 38.67 38.67 0 011.25-3.67c.148-.452.342-.888.58-1.3a8.81 8.81 0 01-.4 1.37c-.3.86-.68 2.12-1.09 3.69a54.125 54.125 0 00-1.17 5.59 61.952 61.952 0 00-.63 7 57.6 57.6 0 00.27 7c.19 1.894.48 3.777.87 5.64.33 1.59.64 2.87.89 3.74.131.432.228.873.29 1.32z"
          ></path>
          <path
            id="elryn86y8smx"
            fill="#fff"
            d="M126.94 186.199a8.723 8.723 0 01-1.21 2.67 8.9 8.9 0 01-1.65 2.43 8.901 8.901 0 011.22-2.67 9.305 9.305 0 011.64-2.43z"
          ></path>
          <path
            id="elr7ldtm8qm3q"
            fill="#263238"
            d="M248 142.1c-1.19 1.9-.17 4.41 1.16 6.21 1.33 1.8 3 3.53 3.3 5.75.35 3.05-2.23 5.72-5 7a5.389 5.389 0 00-2.36 1.47c-.78 1.08-.46 2.56-.49 3.89a6.445 6.445 0 01-3.32 5.57 5.377 5.377 0 01-6.27-1 7.734 7.734 0 01-1.62-3.6 16.362 16.362 0 013.57-14.18c1.69-1.86 3.82-3.26 5.67-5 1.85-1.74 3.46-3.9 3.62-6.41"
          ></path>
          <path
            id="eldkdo5qa1yi"
            fill="#263238"
            d="M247.66 119.339c-1.39-3.58-5-5.79-8.66-7.06a24.508 24.508 0 00-16.25-.35c-7.62 2.76-12.74 9.86-16.68 16.94-1.71 3.08-3.63 6.52-7 7.55-1.8.54-3.73.29-5.6.5a11.495 11.495 0 00-7.48 4c-2.24 2.72-3.4 6.63-6.62 8.06-2.08.92-4.47.53-6.74.6-4.73.15-9.71 3-10.75 7.61-.69 3 .35 6.38-1 9.13-.5 1-1.3 1.84-1.78 2.85a5.188 5.188 0 007.55 6.55 13.673 13.673 0 012.07-1.42c1.64-.69 3.51.22 4.94 1.28 1.43 1.06 2.84 2.36 4.61 2.56 3.62.4 6.43-4 10.07-3.72 2 .14 3.65 1.67 5.4 2.72 3.06 1.84 4.6.19 7.19-2.26 2.59-2.45 4 7.87 10 5.18 5.58-2.52.06-12.07 2.1-11.85 2.04.22 7.4-.11 8.54-1.82"
          ></path>
          <path
            id="ele7gx6mq2hc"
            fill="#263238"
            d="M248.36 130a13.576 13.576 0 00-1.58-12.31 17.782 17.782 0 00-7.64-5.82 19.107 19.107 0 00-12.75-1.18c-4.18 1.22-7.85 4.57-8.78 8.82-.42 1.93-.3 4-1.14 5.79a4.307 4.307 0 00-.78 2.41 2 2 0 001.6 1.54 4.48 4.48 0 002.32-.2 24.418 24.418 0 007-3.89"
          ></path>
          <path
            id="el2icia2ap27k"
            fill="#B78876"
            d="M234.45 167.699c.47-3.69.64-5.2.64-5.17 0 .03 7.93-.41 10.69-10.38 1.38-5 2.06-13.27 2.41-20.09.3-6.13-2.89-14.07-9-14.11l-19.5 2.64a3.59 3.59 0 00-3.61 3.26l-3.08 44.4s8.8 10.42 16.44 9c7.64-1.42 5.01-9.55 5.01-9.55z"
          ></path>
          <path
            id="elpcqdpuf60hp"
            fill="#263238"
            d="M244.93 138.9a1.307 1.307 0 01-1.4 1.17 1.249 1.249 0 01-1.21-1.33 1.307 1.307 0 011.4-1.17 1.249 1.249 0 011.21 1.33z"
          ></path>
          <path
            id="elyc54d92bhcc"
            fill="#263238"
            d="M245.9 136.169c-.18.15-1.08-.69-2.49-.85-1.41-.16-2.51.43-2.64.24-.13-.19.14-.37.62-.65a3.669 3.669 0 012.14-.4 3.39 3.39 0 011.95.89c.4.38.52.7.42.77z"
          ></path>
          <path
            id="elh1tkvvfo7t4"
            fill="#263238"
            d="M232.13 137.57a1.318 1.318 0 01-1.41 1.18 1.268 1.268 0 01-1.139-.838 1.27 1.27 0 01-.071-.493 1.31 1.31 0 011.41-1.169 1.242 1.242 0 01.883.404 1.249 1.249 0 01.327.916z"
          ></path>
          <path
            id="elxk33kktmzrc"
            fill="#263238"
            d="M232.09 134.859c-.18.16-1.08-.69-2.48-.84-1.4-.15-2.51.43-2.65.24-.14-.19.14-.37.62-.65a3.677 3.677 0 012.14-.4 3.515 3.515 0 012 .88c.35.38.46.71.37.77z"
          ></path>
          <path
            id="el519db3zq39r"
            fill="#263238"
            d="M236 145.84a8.985 8.985 0 012.32-.16c.37 0 .71 0 .8-.26.072-.367.03-.747-.12-1.09-.24-.9-.49-1.85-.75-2.85a47.442 47.442 0 01-1.57-7.39c.17 0 1.13 3.2 2.16 7.25.25 1 .48 1.95.71 2.86.174.457.174.962 0 1.42a.907.907 0 01-.64.46 3.38 3.38 0 01-.62 0 9.538 9.538 0 01-2.29-.24z"
          ></path>
          <path
            id="elx8gie65lorh"
            fill="#AA6550"
            d="M235.09 162.529a25.94 25.94 0 01-13.19-5.17s2.62 7.42 12.89 7.62l.3-2.45z"
          ></path>
          <path
            id="elv0ip9h264nk"
            fill="#AA6550"
            d="M235 149.15a2.531 2.531 0 00-2.15-1.2 2.308 2.308 0 00-1.69.68 1.441 1.441 0 00-.3 1.65 1.698 1.698 0 001.78.68 5.336 5.336 0 001.91-.87c.175-.089.328-.216.45-.37a.487.487 0 00.06-.52"
          ></path>
          <path
            id="elii1vq6xk529"
            fill="#263238"
            d="M232.76 129.779c-.18.36-1.56 0-3.22 0-1.66 0-3 .31-3.23-.05-.08-.18.19-.51.77-.82a5.594 5.594 0 014.92 0c.57.36.84.69.76.87z"
          ></path>
          <path
            id="ellm3dbehw6w"
            fill="#263238"
            d="M246.19 130c-.28.29-1.21-.15-2.37-.3-1.16-.15-2.17 0-2.36-.38-.08-.18.11-.48.58-.73a3.457 3.457 0 013.8.55c.38.36.48.71.35.86z"
          ></path>
          <path
            id="eluxo316u97g"
            fill="#B78876"
            d="M217 141.189c.08-.76-.59-2.64-1.32-2.84-2-.54-5.58-.79-6.18 4.28-.84 6.91 6.28 6.32 6.32 6.13.04-.19.89-4.96 1.18-7.57z"
          ></path>
          <path
            id="elqjcwvgwjovg"
            fill="#AA6550"
            d="M214 145.879s-.13.07-.35.14c-.3.09-.624.058-.9-.09a2.993 2.993 0 01-1.06-2.84 3.995 3.995 0 01.55-1.69 1.377 1.377 0 011-.81.614.614 0 01.67.41c.07.21 0 .34 0 .35 0 .01.16-.09.14-.39a.735.735 0 00-.22-.46.882.882 0 00-.64-.26 1.71 1.71 0 00-1.39.92 4.099 4.099 0 00-.65 1.88c-.12 1.4.43 2.79 1.4 3.2a1.267 1.267 0 001.12-.05c.33-.14.33-.29.33-.31z"
          ></path>
          <path
            id="eljhrkz6nu27s"
            fill="#263238"
            d="M240.25 116.86a9.52 9.52 0 01-7.11 6.64c-1.79.37-3.9.31-5.07 1.71-1.17 1.4-.79 3.7-1.92 5.18-1.57 2.06-5.27 1.6-6.52 3.86-.54 1-.42 2.24-1 3.22a3.23 3.23 0 01-4 1.13 5.154 5.154 0 01-2.73-3.4 14.005 14.005 0 01-.33-4.47 24.75 24.75 0 011.55-8.68c2.42-5.7 11.71-13 17.82-12"
          ></path>
          <path
            id="elp0z9ak2lxe7"
            fill="#263238"
            d="M247.22 144.169c1.58-1.87 3.08-4 4.67-5.83 1.59-1.83 3.05-3.68 3.24-6a9.671 9.671 0 00-1.69-5.52 69.442 69.442 0 00-6.59-9 9.015 9.015 0 00-1.85-1.82 4.495 4.495 0 00-2.09-.7 2.524 2.524 0 00-1.74.42 1.387 1.387 0 00-.51 1.61c.322.466.784.817 1.32 1 2.88 1.47 4.73 4.48 5.55 7.61.82 3.13.59 6.15.52 9.39-.06 2.53-.4 5.22-.66 7.55"
          ></path>
          <path
            id="elv90z9hdetej"
            fill="#263238"
            d="M231 110.25a20.997 20.997 0 00-19.19 8.08c-2.41 3.24-4.19 7.46-8 8.82-3.11 1.11-6.6-.13-9.86.45a9.118 9.118 0 00-6.69 5.3c-.93 2.18-1 4.8-2.67 6.52-2.14 2.27-6 2-8.23 4.25-1.8 1.84-1.86 4.8-1.14 7.28a4.077 4.077 0 001.7 2.61 4.174 4.174 0 002.73.21 30.995 30.995 0 008.92-3.53c6.37-3.42 12.5-7.27 18.63-11.11a38.174 38.174 0 008-6.07c3-3.26 4.75-7.43 6.85-11.34 2.1-3.91 4.79-7.77 8.81-9.62"
          ></path>
          <path
            id="elwxyaufdumlr"
            fill="#455A64"
            d="M162.3 176.19a.946.946 0 01-.28-.09 3.155 3.155 0 01-.77-.38 5.003 5.003 0 01-1.89-2.65 6.175 6.175 0 01-.231-2.605A6.17 6.17 0 01160 168a10.463 10.463 0 015.43-4.13 52.274 52.274 0 018.12-1.78 19.232 19.232 0 004.36-1.34 8.745 8.745 0 005.5-7.55c.174-1.793.475-3.57.9-5.32a13.679 13.679 0 017.36-8.4 13.001 13.001 0 015.66-1.22c1.91 0 3.76.34 5.56.46a12.37 12.37 0 005.2-.55 6.393 6.393 0 002.15-1.28 7.684 7.684 0 001.47-2c.79-1.44 1.31-3 2.17-4.4a11.102 11.102 0 013.31-3.31 26.73 26.73 0 017.75-3 47.406 47.406 0 006.54-2 16.426 16.426 0 004.42-2.57 9.762 9.762 0 002.07-2.47c.37-.64.51-1 .54-1a6.794 6.794 0 01-.45 1 9.019 9.019 0 01-2 2.56 15.89 15.89 0 01-4.44 2.68c-2.13.831-4.319 1.5-6.55 2a26.695 26.695 0 00-7.61 3 10.485 10.485 0 00-3.17 3.19c-.83 1.32-1.33 2.89-2.14 4.39a7.83 7.83 0 01-1.56 2.09 6.798 6.798 0 01-2.29 1.38 12.764 12.764 0 01-5.4.59c-1.84-.12-3.67-.43-5.53-.45a12.436 12.436 0 00-5.45 1.18 13.157 13.157 0 00-4.417 3.281 13.162 13.162 0 00-2.693 4.799 39.512 39.512 0 00-.9 5.26 10.32 10.32 0 01-1.83 4.87 10.215 10.215 0 01-3.95 3 19.058 19.058 0 01-4.46 1.35 53.552 53.552 0 00-8.08 1.71 10.22 10.22 0 00-5.31 4 6.005 6.005 0 00-.67 4.87 5.002 5.002 0 001.77 2.62c.294.243.601.47.92.68z"
          ></path>
          <path
            id="elq1b9enq201"
            fill="#455A64"
            d="M162.92 154.94c.19-.268.427-.498.7-.68a4.773 4.773 0 012.67-.83 13.276 13.276 0 014.38.74c.873.273 1.765.477 2.67.61a5.873 5.873 0 003-.29 6.213 6.213 0 002.56-2.05 11.406 11.406 0 001.66-3.22c.43-1.19.76-2.47 1.21-3.76a24.968 24.968 0 011.73-3.87 14.61 14.61 0 016.35-6.39 11.71 11.71 0 014.73-1c1.63-.05 3.26.06 4.89-.09a7.192 7.192 0 004.34-1.72 13.012 13.012 0 002.59-3.86 19.755 19.755 0 012.38-4 11.316 11.316 0 013.53-2.75 22.192 22.192 0 017.94-2c2.55-.25 4.93-.34 7.07-.65a18.79 18.79 0 005.57-1.57 12.236 12.236 0 003.57-2.52 8.509 8.509 0 001.59-2.25c.28-.57.39-.9.41-.89-.073.32-.18.632-.32.93a8.197 8.197 0 01-1.54 2.34 12.028 12.028 0 01-3.59 2.63 18.545 18.545 0 01-5.64 1.67c-2.16.34-4.55.45-7.08.71a21.97 21.97 0 00-7.77 2 10.752 10.752 0 00-3.38 2.65 19.184 19.184 0 00-2.31 3.9 13.345 13.345 0 01-2.69 4 7.67 7.67 0 01-4.62 1.85c-1.69.15-3.33 0-4.93.09a11.14 11.14 0 00-4.54.94 14.278 14.278 0 00-6.16 6.17 26.004 26.004 0 00-1.71 3.79c-.46 1.27-.79 2.55-1.24 3.76-.83 2.41-2.33 4.62-4.47 5.45a6.234 6.234 0 01-3.14.28 17.871 17.871 0 01-2.7-.65 13.351 13.351 0 00-4.3-.82 4.995 4.995 0 00-2.63.72c-.55.38-.76.69-.78.63z"
          ></path>
          <path
            id="elyufn65xql4"
            fill="#455A64"
            d="M193.35 175.999s.16 0 .42-.19c.372-.228.649-.583.78-1a2.172 2.172 0 00-.32-2 5.269 5.269 0 00-2.17-1.67 10.814 10.814 0 01-3-1.75 6 6 0 01-1.68-3.68 7.588 7.588 0 01.9-4.37 9.855 9.855 0 013.33-3.44 23.3 23.3 0 018.66-2.91 37.097 37.097 0 007.16-1.79 12.857 12.857 0 004.19-2.6c.85-.82 1.19-1.38 1.23-1.35.04.03-.06.15-.23.41-.254.382-.542.74-.86 1.07a12.38 12.38 0 01-4.19 2.79 35.185 35.185 0 01-7.21 1.91c-2.73.54-5.8 1.14-8.48 2.88a9.53 9.53 0 00-3.18 3.26 7.253 7.253 0 00-.87 4.1 5.547 5.547 0 001.52 3.42 10.785 10.785 0 002.9 1.74 5.415 5.415 0 012.25 1.83 2.361 2.361 0 01.27 2.16 1.84 1.84 0 01-.91 1 .907.907 0 01-.51.18z"
          ></path>
          <path
            id="elil1eb7np8vo"
            fill="#B78876"
            d="M205.91 228.179s-1.15-1.249-.77-2.409c.38-1.16 1.06-2 1.35-1.92.29.08.19-6.261 1.35-6.741a16.679 16.679 0 012.5-.77s1.35-3.46 2.12-3.37c.77.09 9.33 1.64 9.33 1.64s6.74 7.22 6.35 7.89c-.39.67-.77 1.44-1.83 1.06a11.92 11.92 0 01-2.07-1.06l3.59 3.93s-.69 3-3.63.88l1.44 1.83s-.87 3.76-5.1.58l-4.33-2.6h-2.79l6.35 3.88s.67 1.91-1.25 1.9c-1.92-.01-6.76-2.56-6.76-2.56s-2.88 1.7-3.85 1.7c-.97 0-1.23-1.07-1.23-1.07l-.77-2.79z"
          ></path>
          <path
            id="elk74igg4efbr"
            fill="#AA6550"
            d="M224.36 227.52a16.507 16.507 0 01-1.49-1.28 32.438 32.438 0 01-3.52-3.19 3.004 3.004 0 00-2.16-1 14.61 14.61 0 00-2.09.13 3.934 3.934 0 01-2-.09c0-.07.75 0 1.93-.25a11.37 11.37 0 012.16-.25c.449.006.893.094 1.31.26.449.183.851.463 1.18.82 1.23 1.38 2.53 2.41 3.4 3.29.526.43.961.96 1.28 1.56z"
          ></path>
          <path
            id="elhh277k8ya"
            fill="#AA6550"
            d="M227.83 226.43a12.04 12.04 0 01-1.75-1.71c-1.05-1.09-2.5-2.59-4.14-4.2-.83-.79-1.6-1.6-2.35-2.19a4.345 4.345 0 00-2.35-.85c-1.48-.1-2.42 0-2.43 0 .202-.092.419-.149.64-.17.598-.096 1.204-.14 1.81-.13a4.59 4.59 0 012.6.83c.849.692 1.661 1.43 2.43 2.21 1.65 1.62 3.06 3.16 4 4.31a11.292 11.292 0 011.54 1.9z"
          ></path>
          <path
            id="elq2h191g9unc"
            fill="#263238"
            d="M230.87 147.1c.26-.11.85.81 1.76 1.73.91.92 1.81 1.53 1.7 1.79-.11.26-1.34 0-2.4-1.1-1.06-1.1-1.31-2.34-1.06-2.42z"
          ></path>
          <path
            id="elgwq6xx01de"
            fill="#13CB6E"
            d="M304.68 227.28l-8.89-45.7a3.43 3.43 0 00-6.73 1.31l.41 2.08-18.39 4.52a12.274 12.274 0 00-8.59 7.67l-11.09 2.73a7.169 7.169 0 00-5.25 8.67l1 4-10.85 2.68a5.627 5.627 0 00-3.476 2.558 5.636 5.636 0 001.909 7.743 5.652 5.652 0 004.267.649l10.86-2.67.77 3.13a7.17 7.17 0 008.67 5.25l11.29-2.78v-.09a12.253 12.253 0 0010.89 2.59l16.32-4 .19 1a3.431 3.431 0 006.156 1.198c.509-.73.715-1.629.574-2.508l-.04-.03z"
          ></path>
          <path
            id="elgywzqm0t4qu"
            fill="#263238"
            d="M304.68 227.28c.07.25.1.51.09.77a3.431 3.431 0 01-.78 2.12 3.539 3.539 0 01-3.38 1.2A3.503 3.503 0 01298 229c-.13-.44-.19-.89-.29-1.35l.13.08-12 3-3.59.9a13.901 13.901 0 01-3.91.54 12.488 12.488 0 01-7.88-3l.3-.19v.08l.05.19h-.19c-3.81.93-7.8 2-12.09 2.95a7.416 7.416 0 01-6.27-2.2 7.259 7.259 0 01-1.77-3.05c-.31-1.14-.57-2.28-.86-3.43l.27.17-9.16 2.31c-.79.17-1.54.41-2.39.54a5.863 5.863 0 01-5.77-8.77 6.162 6.162 0 014.29-2.89l10.22-2.51-.18.29c-.2-.83-.41-1.69-.63-2.56a16.736 16.736 0 01-.55-2.73 7.4 7.4 0 013.84-7.03 9.779 9.779 0 012.6-.9l10.26-2.52-.16.15a12.643 12.643 0 015.17-6.31 12.747 12.747 0 013.74-1.53l3.8-.94 14.44-3.53-.16.25c-.11-.54-.21-1.09-.32-1.63a4.52 4.52 0 01-.11-1.69 3.621 3.621 0 011.909-2.645A3.623 3.623 0 01294 179c.499.227.938.567 1.285.992.347.424.592.923.715 1.458l2.19 11.31c1.36 7.1 2.55 13.3 3.53 18.43.98 5.13 1.73 9.13 2.26 11.9.25 1.36.44 2.41.57 3.14.06.35.11.61.14.81.009.093.009.186 0 .28a1.22 1.22 0 01-.07-.27c0-.2-.1-.46-.17-.8l-.65-3.14c-.55-2.76-1.35-6.78-2.37-11.87-1.02-5.09-2.23-11.32-3.63-18.41l-2.23-11.29a3.196 3.196 0 00-1.76-2.17 3.207 3.207 0 00-2.88.065 3.208 3.208 0 00-1.66 2.355 3.898 3.898 0 00.1 1.55c.11.54.22 1.08.32 1.63v.2l-.2.05-14.43 3.57c-2.49.69-5.17 1-7.4 2.41a12.184 12.184 0 00-5 6.08l-.05.12h-.12l-10.26 2.53a9.16 9.16 0 00-2.48.85c-.764.42-1.442.98-2 1.65a6.88 6.88 0 00-1.58 4.91c.103.897.284 1.784.54 2.65.21.88.43 1.74.63 2.57l.06.23-.24.06-10.16 2.51a5.603 5.603 0 00-4 2.66 5.368 5.368 0 002.4 7.537 5.371 5.371 0 002.89.463c.76-.1 1.56-.35 2.34-.53l9.15-2.24.22-.05.05.21c.29 1.15.55 2.31.85 3.42a6.856 6.856 0 001.66 2.87 7.006 7.006 0 005.9 2.09c4.21-.94 8.27-2 12.06-2.91l-.13.23v-.08l-.14-.57.44.38a12.102 12.102 0 007.65 2.91 13.48 13.48 0 003.82-.52l3.59-.87 12-2.91h.11v.11c.1.46.16.93.28 1.34.12.403.317.78.58 1.11a3.47 3.47 0 001.91 1.18 3.402 3.402 0 002.763-.643c.387-.3.704-.681.929-1.116.226-.434.355-.912.378-1.401a7.497 7.497 0 00-.02-.75z"
          ></path>
          <path
            id="el0axql7rmfkt"
            fill="#263238"
            d="M298.16 227.52c-.13 0-2.27-9.46-4.78-21.18-2.51-11.72-4.45-21.26-4.31-21.29.14-.03 2.27 9.45 4.79 21.18s4.44 21.26 4.3 21.29z"
          ></path>
          <path
            id="elwelwdxq62d"
            fill="#263238"
            d="M270.55 229c-.13 0-2-7.09-4.26-15.9s-3.91-16-3.78-16c.13 0 2 7.09 4.26 15.92s3.91 15.98 3.78 15.98z"
          ></path>
          <path
            id="el2bzxdg0xhb2"
            fill="#263238"
            d="M249.84 223.52a24.75 24.75 0 01-1.59-5.4 46.105 46.105 0 01-1.1-5.52 25.106 25.106 0 011.58 5.41c.479 1.813.85 3.652 1.11 5.51z"
          ></path>
          <path
            id="elnkg59jd89kr"
            fill="#fff"
            d="M259.7 199.789c0 .07-1.07.23-2.79.59-.86.18-1.88.41-3 .72-1.205.31-2.367.767-3.46 1.36a4.794 4.794 0 00-2.45 2.54 7.396 7.396 0 00-.06 3c.22 1.73.51 2.79.44 2.81a9.156 9.156 0 01-.79-2.76 7.385 7.385 0 010-3.16 3.63 3.63 0 011-1.65 7.827 7.827 0 011.63-1.2 14.195 14.195 0 013.58-1.38c1.14-.29 2.18-.48 3-.62a11.04 11.04 0 012.9-.25z"
          ></path>
          <path
            id="elnahsv639atn"
            fill="#fff"
            d="M286 187.659c-.3.145-.62.243-.95.29-.61.15-1.51.36-2.62.6l-8.66 1.82a18.343 18.343 0 00-4.52 1.58 9.428 9.428 0 00-3 2.46 8.169 8.169 0 00-1.49 3.26 2.256 2.256 0 01.07-1 6.857 6.857 0 011.17-2.47 9.384 9.384 0 013.05-2.64 17.28 17.28 0 014.63-1.67c3.4-.73 6.47-1.27 8.71-1.68 1.11-.2 2-.35 2.65-.45a4.42 4.42 0 01.96-.1z"
          ></path>
          <path
            id="elwmso7scyby"
            fill="#fff"
            d="M245.25 215.089a4.815 4.815 0 01-1.93.77c-1.25.34-3 .48-4.89.88a9.805 9.805 0 00-2.51.88 6.349 6.349 0 00-1.71 1.3 9.187 9.187 0 00-1.09 1.7 3.115 3.115 0 01.82-1.92 5.997 5.997 0 011.76-1.48 9.057 9.057 0 012.62-1c1.93-.41 3.69-.49 4.92-.74s1.98-.48 2.01-.39z"
          ></path>
          <path
            id="els71ptt825jo"
            fill="#fff"
            d="M293.49 179.88c0 .08-.37.07-.9.21a3.614 3.614 0 00-1.75 1.08 3.494 3.494 0 00-.79 1.91c-.06.54 0 .9-.07.91-.07.01-.25-.31-.28-.92a3.227 3.227 0 01.76-2.22c.532-.609 1.268-1 2.07-1.1.61-.07.97.07.96.13z"
          ></path>
        </motion.g>
        <g
          id="text"
          className="text-font-black dark:text-font-white"
          fill="currentColor"
        >
          <path
            id="elhhbed1r2br"
            d="M296.22 101.19c0 3.62-2.61 5.8-6.93 5.8h-4.56v5.36h-1.79v-17h6.35c4.32.02 6.93 2.21 6.93 5.84zm-1.79 0c0-2.71-1.79-4.26-5.19-4.26h-4.51v8.48h4.51c3.4 0 5.19-1.55 5.19-4.22z"
          ></path>
          <path
            id="elq7h94dv5u8"
            d="M309.24 104.44v7.91h-1.65v-2a4.767 4.767 0 01-4.38 2.11c-2.89 0-4.66-1.51-4.66-3.71 0-2 1.26-3.61 4.92-3.61h4v-.78c0-2.18-1.23-3.37-3.61-3.37a6.482 6.482 0 00-4.24 1.5l-.78-1.28a8.115 8.115 0 015.19-1.72c3.35.01 5.21 1.65 5.21 4.95zm-1.72 4.07v-2.08h-4c-2.47 0-3.27 1-3.27 2.28 0 1.48 1.19 2.4 3.22 2.4a4.081 4.081 0 004.05-2.6z"
          ></path>
          <path
            id="elsoc70cnv9jr"
            d="M325.78 99.59v11.2c0 4.39-2.16 6.38-6.38 6.38a8.875 8.875 0 01-5.94-2l.87-1.31a7.615 7.615 0 005 1.74c3.23 0 4.71-1.48 4.71-4.63v-1.62a5.759 5.759 0 01-5 2.47 6.18 6.18 0 110-12.36 5.757 5.757 0 015 2.54v-2.41h1.74zm-1.68 6.06a4.84 4.84 0 10-4.84 4.68 4.54 4.54 0 003.455-1.269 4.56 4.56 0 001.385-3.411z"
          ></path>
          <path
            id="eljdqi3exhc9h"
            d="M341.7 106.5H331a4.72 4.72 0 005 4.44 4.993 4.993 0 003.91-1.67l1 1.11a6.737 6.737 0 01-10.328-.715A6.744 6.744 0 01329.31 106a6.147 6.147 0 011.715-4.59 6.144 6.144 0 014.525-1.88c3.58 0 6.18 2.64 6.18 6.47 0 .11-.03.31-.03.5zm-10.7-1.28h9a4.537 4.537 0 00-1.486-2.862A4.533 4.533 0 00331 105.22z"
          ></path>
          <path
            id="el3yc9xqx1ci8"
            d="M363.33 105v7.4h-1.72v-7.23c0-2.69-1.41-4.07-3.83-4.07-2.74 0-4.47 1.69-4.47 4.6v6.7h-1.72V99.59h1.65v2.35a5.356 5.356 0 014.85-2.44c3.1 0 5.24 1.79 5.24 5.5z"
          ></path>
          <path
            id="elacoy5bx2fwk"
            d="M366.77 106a6.487 6.487 0 014.011-5.988 6.489 6.489 0 016.091 11.39 6.498 6.498 0 01-3.602 1.098 6.262 6.262 0 01-4.668-1.833A6.266 6.266 0 01366.77 106zm11.23 0a4.75 4.75 0 10-4.72 5 4.653 4.653 0 004.72-5z"
          ></path>
          <path
            id="elp62s0pdd2m"
            d="M390 111.57a4.096 4.096 0 01-2.69.9 3.387 3.387 0 01-3.562-2.224 3.368 3.368 0 01-.178-1.486v-7.71h-2.27v-1.46h2.27v-2.78h1.73v2.78h3.87v1.46h-3.87v7.61a2 2 0 002.2 2.33 2.815 2.815 0 001.89-.66l.61 1.24z"
          ></path>
          <path
            id="elociti7acxk"
            d="M401.41 98.09v1.5h3.93v1.46h-3.88v11.3h-1.72v-11.3h-2.28v-1.46h2.28V98a3.507 3.507 0 011.039-2.793 3.516 3.516 0 012.811-.987 3.999 3.999 0 012.55.8l-.58 1.28a3.002 3.002 0 00-1.89-.63c-1.48.02-2.26.84-2.26 2.42z"
          ></path>
          <path
            id="elysptbas2uw"
            d="M406.16 106a6.487 6.487 0 014.011-5.988 6.489 6.489 0 016.091 11.39 6.498 6.498 0 01-3.602 1.098 6.286 6.286 0 01-6.068-3.977 6.274 6.274 0 01-.432-2.523zm11.23 0a4.748 4.748 0 00-5.828-4.375A4.75 4.75 0 00412.66 111a4.672 4.672 0 003.468-1.457A4.662 4.662 0 00417.39 106z"
          ></path>
          <path
            id="el51xn17qzt68"
            d="M434.14 99.59v12.76h-1.65V110a5.075 5.075 0 01-4.58 2.45c-3.25 0-5.38-1.82-5.38-5.48v-7.4h1.72v7.23c0 2.71 1.4 4.09 3.83 4.09 2.66 0 4.34-1.72 4.34-4.63v-6.67h1.72z"
          ></path>
          <path
            id="elc6gwmi6yya7"
            d="M450.7 105v7.4H449v-7.23c0-2.69-1.41-4.07-3.84-4.07-2.73 0-4.46 1.69-4.46 4.6v6.7H439V99.59h1.65v2.35a5.356 5.356 0 014.85-2.44c3.06 0 5.2 1.79 5.2 5.5z"
          ></path>
          <path
            id="elx064pghephd"
            d="M467.06 94.36v18h-1.65v-2.53a5.503 5.503 0 01-4.92 2.65 6.499 6.499 0 110-13 5.568 5.568 0 014.85 2.52v-7.64h1.72zm-1.7 11.64a4.75 4.75 0 10-4.72 5 4.65 4.65 0 003.461-1.46 4.672 4.672 0 001.259-3.54z"
          ></path>
          <path
            id="el300jtkke9vv"
            d="M347 72.63h-8.89v11.45h-12.3V72.63h-29.39v-8.48l25.71-34.59h13.24l-23.83 32.79h14.64v-10.2h11.91v10.2H347v10.28z"
          ></path>
          <path
            id="el95axp9ky7fv"
            d="M350.74 56.82c0-18 10-28.19 23.37-28.19 13.37 0 23.36 10.2 23.36 28.19 0 17.99-9.89 28.18-23.36 28.18-13.47 0-23.37-10.18-23.37-28.18zm34 0c0-12.38-4.36-17.52-10.67-17.52s-10.6 5.14-10.6 17.52c0 12.38 4.37 17.53 10.6 17.53 6.23 0 10.71-5.14 10.71-17.53h-.04z"
          ></path>
          <path
            id="elysrvxz3shm"
            d="M453.58 72.63h-8.88v11.45h-12.31V72.63H403v-8.48l25.7-34.59H442l-23.86 32.79h14.64v-10.2h11.92v10.2h8.88v10.28z"
          ></path>
        </g>
        <g id="freepik Lines inject 39" fill="#263238">
          <path
            id="elepiizb0ilfu"
            d="M439.18 173.14a.754.754 0 01-.18.12l-.57.31-2.21 1.15-8.2 4.19-27.52 13.86-.18.1-.13-.17c-.76-1.09-1.57-2.24-2.4-3.4l-3.41-4.84.32.07-25.65 14.81-7.64 4.36-2.08 1.15c-.22.12-.39.22-.54.29-.15.07-.19.1-.19.09 0-.01 0 0 .17-.13l.52-.33 2-1.23 7.57-4.48 25.56-14.95.19-.12.13.19c1.1 1.54 2.25 3.18 3.43 4.83.82 1.17 1.63 2.32 2.4 3.4l-.31-.08 27.58-13.72 8.27-4.05 2.25-1.08.58-.26.24-.08z"
          ></path>
          <path
            id="el6tf19rd5mgm"
            d="M431.89 209.26a2.79 2.79 0 01-.54.15l-1.58.35-5.86 1.24-19.61 3.8-.24.05v-.24l-.44-2.43c-.09-.55-.19-1.1-.28-1.6l.28.2c-7.95 1.36-15 3-20 4.36-2.53.66-4.55 1.23-6 1.62l-1.61.45c-.185.06-.376.1-.57.12.172-.091.353-.165.54-.22l1.58-.53c1.39-.45 3.4-1.07 5.92-1.77a202.502 202.502 0 0120.05-4.52h.24v.24c.09.51.19 1.06.29 1.6.15.84.29 1.66.43 2.43l-.29-.2 19.64-3.65 5.88-1 1.6-.26c.179-.092.372-.156.57-.19z"
          ></path>
          <path
            id="ellskcz1jjnm"
            d="M364.85 174.55c.12.06-2.42 5.56-5.69 12.28-3.27 6.72-6 12.12-6.13 12.06-.13-.06 2.43-5.55 5.69-12.28 3.26-6.73 6.01-12.12 6.13-12.06z"
          ></path>
          <path
            id="el1ysc02xgpsj"
            d="M394.71 201.51c0 .13-6.29 2.09-14.13 4.38-7.84 2.29-14.24 4-14.27 3.91-.03-.09 6.28-2.09 14.13-4.38 7.85-2.29 14.23-4.04 14.27-3.91z"
          ></path>
          <path
            id="el0pcn656chni"
            d="M345.77 157.34c.171.318.308.653.41 1 .26.74.6 1.72 1 2.92.87 2.58 2.05 6.14 3.45 10.33l.1.3h-.32l-7.81-.16.25-.29c.18 1 .38 2 .58 3.11 1.28 7 2.43 13.42 3.29 18.12.39 2.27.71 4.13.94 5.5.09.6.17 1.1.23 1.5.041.173.058.352.05.53a2.062 2.062 0 01-.15-.51c-.08-.4-.19-.89-.32-1.49l-1.09-5.47c-.89-4.69-2.1-11.06-3.44-18.1-.19-1.06-.39-2.1-.57-3.1l-.06-.29h.3l7.81.2-.21.3-3.28-10.39c-.37-1.22-.67-2.21-.89-3a8.043 8.043 0 01-.27-1.01z"
          ></path>
          <path
            id="elt88puisf4ph"
            d="M311.24 232.69c-.06-.12 5-2.62 11.33-5.58s11.48-5.26 11.54-5.14c.06.12-5 2.63-11.33 5.59s-11.48 5.25-11.54 5.13z"
          ></path>
          <path
            id="elnlggczid3wq"
            d="M334.82 239.94a50.145 50.145 0 014.32-5.58 53.036 53.036 0 014.71-5.26 48.653 48.653 0 01-4.33 5.58 51.075 51.075 0 01-4.7 5.26z"
          ></path>
          <path
            id="el8e12cmfsl6g"
            d="M347.53 274.28c-.13 0 .73-9.75 1.94-21.75s2.29-21.72 2.43-21.7c.14.02-.74 9.75-1.94 21.75s-2.29 21.71-2.43 21.7z"
          ></path>
          <path
            id="elmckagebgoe"
            d="M330.18 188.12a22.47 22.47 0 012.31 4.09 21.838 21.838 0 011.86 4.31 22.732 22.732 0 01-2.3-4.09 22.783 22.783 0 01-1.87-4.31z"
          ></path>
        </g>
      </g>
    </svg>
  );
}
